import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import * as analysisDataActions from 'redux/actions/analysisDataActions'
import { analysis } from 'redux/ApiCalls'
import Spinner from 'components/Spinner'
import ReactTooltip from 'react-tooltip'
import CurrencyInfo from 'components/CurrencyInfo'
import ProgressTableAndExcelExport from 'components/ProgressTableAndExcelExport'

const BusinessPlan = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [lastKeyPress, setLastKeyPress] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const {
    firmBackground,
    financial_statements,
    fx_position,
    maturity_short_term,
    business_plan,
    analysisResult,
  } = useSelector((state) => state.analysisDataReducer)
  const { token } = useSelector((state) => state.auth)
  let { flowOrstock } = firmBackground

  const handleInputChange = (value, key, index) => {
    const numberRegex = /^-?\d*\.?\d+$/

    let newValue = value.replace(/,/g, '')

    if (newValue === '' || newValue.match(numberRegex)) {
      const newBusinessData = [...business_plan]
      const findValue = newBusinessData[index].values.find(
        (item) => item.key === key
      )

      findValue.value =
        newValue !== '' ? Number(newValue).toLocaleString('en-US') : ''
      dispatch(analysisDataActions.businessPlan(newBusinessData))
      setLastKeyPress(newBusinessData)
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => calculateAnalysis(), 1500)
    return () => clearTimeout(timeoutId)
  }, [business_plan, lastKeyPress])

  const trueOrFalse = (() => {
    if (firmBackground.industry === 'Finance & Insurance') {
      return true
    }
    return false
  })()

  const calculateAnalysis = () => {
    setIsLoading(true)
    const postData = {
      analysis_data: {
        financial: {},
        fx: {},
        maturity: {},
        business_plan: {},
        scenario: {},
      },
      firm_background: firmBackground,
      is_analysis_real: trueOrFalse,
      enable_custom_scenario: analysisResult.enable_custom_scenario,
    }

    financial_statements.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.financial[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.financial[value.key].push(newValue)
        })
      }
    })

    fx_position.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.fx[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.fx[value.key].push(newValue)
        })
      }
    })

    maturity_short_term.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.maturity[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.maturity[value.key].push(newValue)
        })
      }
    })

    business_plan.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.business_plan[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.business_plan[value.key].push(newValue)
        })
      }
    })

    if (analysisResult?.scenarios) {
      analysisResult.scenarios.map((item, index) => {
        if (index === 0) {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key] = [newValue]
          })
        } else {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key].push(newValue)
          })
        }
      })
    } else {
      initial_custom_scenario.map((item, index) => {
        if (index === 0) {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key] = [newValue]
          })
        } else {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key].push(newValue)
          })
        }
      })
    }
    analysis(token, postData)
      .then(({ data }) => {
        dispatch(analysisDataActions.fetchRealAnalysis(data))
        setIsLoading(false)
      })
      .catch((err) => err)
  }

  useEffect(() => {
    setIsLoading(false)
  }, [])

  const TableStyle = {
    ThStyle: `min-w-[80px] max-w-[140px] w-[100px] sm:w-[120px] md:w-[110px] lg:w-[140px] xl:w-[130px] p-0 m-0 text-white text-end text-xs `,

    ThStyleHeader: `min-w-[80px] max-w-[140px] w-[100px] sm:w-[120px] md:w-[110px] lg:w-[140px] xl:w-[130px] p-0 m-0 text-black text-end text-xs `,

    ThStyleHeaderText: `min-w-[80px] max-w-[140px] w-[100px] sm:w-[120px] md:w-[110px] lg:w-[140px] xl:w-[130px] p-0 mb-0 text-black text-end text-xs  `,

    ThStyleHeaderLabel: `min-w-[80px] max-w-[140px] w-[100px] sm:w-[120px] md:w-[110px] lg:w-[140px] xl:w-[130px]  p-0 m-0 text-black text-start text-xs `,

    TdStyle: `min-w-[115px] max-w-[140px] w-[100px] sm:w-[120px] md:w-[110px] lg:w-[140px] xl:w-[130px] 
              p-0 m-0 text-end border-l  border-b border-[#d2d5d9] text-xs   `,

    TdLabelStyle: `min-w-[300px] max-w-[300px] w-[300px] sm:w-[300px] md:w-[300px] lg:w-[200px] xl:w-[200px] 
                   p-0 m-0 text-start text-xs    !font-normal !text-sm `,

    TdLabelStyleHeader: `min-w-[200px] max-w-[200px] w-[200px]  sm:w-[200px] md:w-[200px] lg:w-[200px] xl:w-[200px] 
                         p-0 m-0  
                         text-start text-xs  !font-normal !text-sm`,
  };



  const table = (
    <table className="w-full !border border-[#d2d5d9]   rounded-md sm:max-w-full md:max-w-[80%] lg:max-w-[60%] table-auto items-center">

      <thead className="sticky top-0 text-end  z-9">
        <tr >
          <th className={`${TableStyle.ThStyleHeaderText} text-center text-bold bg-white `}>
            <span className="flex">
              {intl.formatMessage({ id: 'FINANCIAL_CHART.CURRENCY' })}{firmBackground.currency_sym}
              <span className="flex ">
                {intl.formatMessage({ id: 'FINANCIAL_CHART.DECIMAL' })}{firmBackground.decimal}
              </span>
            </span>
          </th>

        </tr>
      </thead>
      <thead className="sticky top-[20px] text-end  z-9">
        <tr>
          <th className={TableStyle.ThStyleHeaderText}>
            <CurrencyInfo showFirstButton={true} showSecondButton={true} />
          </th>
          <th className={`${TableStyle.ThStyleHeaderText} text-dark text-center`} colSpan={1}>
            <ProgressTableAndExcelExport showFirstButton={true} />
          </th>
          <th className={`${TableStyle.ThStyleHeader} text-black text-end`} colSpan={1}>
            <ProgressTableAndExcelExport showSecondButton={true} />
          </th>

        </tr>
      </thead>


      <thead className="text-center w-full  bg-primary rounded-md">
        <tr >
          <th className={TableStyle.TdLabelStyle}>
            Business Plan
          </th>
          {business_plan?.map((item, index) => (
            <th key={index} scope="col" className={TableStyle.ThStyle}>
              {new Date(item.year).toLocaleString('en-US', { month: 'short' }) +
                ' ' +
                new Date(item.year).toLocaleString('en-US', { year: 'numeric' })}
            </th>
          ))}
        </tr>
      </thead>
      <tbody >
        {business_plan[0]?.values.map((row, rowIndex) => (
          <tr key={rowIndex} className={'bg-gray-200'}>
            <td
             className={`${TableStyle.TdLabelStyle} text-gray-800 font-normal !border-l  border-b border-[#d2d5d9] `}
              data-tip={
                row.label === 'Increase in Working Capital'
                  ? intl.formatMessage({
                    id: 'tooltip_increase_in_working_capital',
                  })
                  : row.label === 'Increase in Capital Expenditure'
                    ? intl.formatMessage({
                      id: 'tooltip_increase_in_capital_expenditure',
                    })
                    : row.label === 'Increase in Equity Investments'
                      ? intl.formatMessage({
                        id: 'tooltip_increase_in_equity_investment',
                      })
                      : row.label === 'Return on Equity Investments (%)'
                        ? intl.formatMessage({
                          id: 'tooltip_return_on_equity_investments',
                        })
                        : row.label === 'Interest Rate on Borrowings (%)'
                          ? intl.formatMessage({
                            id: 'tooltip_interest_rate_on_borrowings',
                          })
                          : row.label === 'Growth Rate of Sales (%)'
                            ? intl.formatMessage({
                              id: 'tooltip_growth_rate_of_sales',
                            })
                            : row.label === 'Gross Margin (%)'
                              ? intl.formatMessage({
                                id: 'tooltip_gross_margin',
                              })
                              : row.label === 'Capital Increase'
                                ? intl.formatMessage({
                                  id: 'tooltip_capital_increase',
                                })
                                : row.label === 'Increase in Borrowings'
                                  ? intl.formatMessage({
                                    id: 'tooltip_increase_in_borrowings',
                                  })
                                  : ''
              }
            >
              <ReactTooltip />
              <span className="block truncate">
                {intl.formatMessage({ id: row.key })}
              </span>
            </td>
            {business_plan?.map((yearData, columnIndex) => (
              <td key={columnIndex} className={`${TableStyle.TdStyle} bg-white text-end  `}>
                <input onChange={(e) => handleInputChange(e.target.value, row.key, columnIndex)}
                  className="w-full border-none text-end text-xxs focus:outline-none focus:ring-2 focus:ring-blue-500 m-0 py-0" type="text" value={yearData.values[rowIndex]?.value.toLocaleString('en-US')} />

              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )

  return (
    <>
      {isLoading ? <Spinner /> : ''}
      <div>
        {table}
      </div>
    </>
  )
}

export default BusinessPlan
