import { useEffect, useState } from 'react'
import AnalysisButtons from 'components/AnalysisButtons'
import BusinessPlan from 'pages/BusinessPlan'
import CustomScenario from 'pages/CustomScenario'
import FinancialStatements from 'pages/FinancialStatements'
import Firmbackground from 'pages/Firmbackground'
import FxPosition from 'pages/FxPosition'
import MaturityShortTerm from 'pages/MaturityShortTerm'
import Report from 'pages/Report'
import './../assets/styles/fixedTableHeader.scss'
import { getFirmList } from 'redux/ApiCalls'
import { useDispatch, useSelector } from 'react-redux'
import storeConstants from 'redux/constants/actionTypes'
import Sidebar from 'components/Sidebar/Sidebar'
import HeaderAnalysisChart from 'components/HeaderAnalysisChart'

const AnalysisPage = () => {
  const [tab, setTab] = useState(0)
  const { token } = useSelector((state) => state.auth)
  const [isLoading, setIsLoading] = useState(true)
  const [excelRead, setExcelRead] = useState(null)
  const [isLastCustomScenario, setIsLastCustomScenario] = useState(false)

  const { tabsRouter } = useSelector((state) => state.analysisDataReducer)
  const dispatch = useDispatch()

  useEffect(() => {
    if (tab === 1)
      getFirmList(token)
        .then((res) => {
          dispatch({
            type: storeConstants.GET_FIRM_LIST,
            payload: res.data.results,
          })
        })
        .catch((e) => console.log(e))
  }, [tab])

  useEffect(() => {
    if (tabsRouter) {
      setTab(0)
    }
  }, [tabsRouter])


  return (
    <>
      <Sidebar
        changePage={() => setTab(1)}
        tab={tab}
        setTab={setTab}
        isLoading={isLoading}
        token={token}
      />

      <div className="relative md:ml-64   " style={{ background: 'linear-gradient(180deg, #CEE5FD, #FFF)' }}>      {
        tab === 0 && (
          <div className="flex flex-col min-h-screen items-center justify-center">
            <Firmbackground changePage={() => setTab(1)} />
          </div>
        )
      }
        <div >
          {tab !== 0 && <HeaderAnalysisChart />}
          {
            tab !== 0 && <div className="mx-2 bg-white rounded-sm relative !overflow-scroll  !scrollbar-hidden lg:overflow-y-visible h-[490px] overflow-x-auto z-11 !justify-center">
              {tab === 1 ? (
                <div className="m-3">
                  <FinancialStatements
                    excelRead={excelRead}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    setExcelRead={setExcelRead}
                  />
                </div>
              ) : tab === 2 ? (
                <div className="h-[360px] m-3">

                  <FxPosition />
                </div>
              ) : tab === 3 ? (
                <div className="h-[360px] m-3">

                  <MaturityShortTerm />

                </div>
              ) : tab === 4 ? (
                <div className="h-[360px] m-3">
                  <BusinessPlan />

                </div>
              ) : tab === 5 ? (

                <div className="h-[360px] m-3">
                  <CustomScenario
                    isLastCustomScenario={isLastCustomScenario}
                    setIsLastCustomScenario={setIsLastCustomScenario}
                  />
                </div>
              ) : tab === 6 ? (
                <div className="">
                  <Report />
                </div>
              ) : null}
              <div className="sticky left-0 bg-white z-10 p-2 shadow-md">
                <AnalysisButtons activeTab={tab} setActiveTab={setTab} />
              </div>
            </div>
          }
        </div>
        {tab !== 0 && (
          <div className="">
            {/* <AnalysisButtons activeTab={tab} setActiveTab={setTab} /> */}
          </div>
        )}
      </div>
    </>

  )
}

export default AnalysisPage
