/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  FaChevronDown,
  FaChevronUp,
  FaFingerprint,
  FaUserPlus,
  FaSignOutAlt,
  FaHome,
  FaTimes,
  FaBars
} from "react-icons/fa";
import { PiEqualizer } from "react-icons/pi";
import { FaMoneyCheckDollar } from "react-icons/fa6";

import { FaMagnifyingGlassChart } from "react-icons/fa6";

import TabsRouter from "components/TabsRouter";
import storeConstants from "redux/constants/actionTypes";
import { getFirmList, logOut } from "redux/ApiCalls";
import { toast } from "react-toastify";
import { FaHandshake } from "react-icons/fa";
import CountryFlag from "components/CountryFlag";

export default function Sidebar({ tab, setTab, token }) {
  const { tabsRouter } = useSelector((state) => state.analysisDataReducer);
  const auth = useSelector((state) => state.auth);
  const intl = useIntl();
  const [collapseShow, setCollapseShow] = useState(false);
  const [isOpenRequest, setIsOpenRequest] = useState(true);
  const [isOpenAnalysis, setIsOpenAnalysis] = useState(true);
  const [isOpenAccount, setIsOpenAccount] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (tab === 1) {
      getFirmList(token)
        .then((res) => {
          dispatch({
            type: storeConstants.GET_FIRM_LIST,
            payload: res.data.results,
          });
        })
        .catch((e) => console.log(e));
    }
  }, [tab, dispatch, token]);

  useEffect(() => {
    if (tabsRouter) setTab(0);
  }, [tabsRouter, setTab]);



  const toggleAccordionRequest = () => {
    setIsOpenRequest(!isOpenRequest);
  };

  const toggleAccordionAnalysis = () => {
    setIsOpenAnalysis(!isOpenAnalysis);
  };
  const toggleAccordionAccount = () => {
    setIsOpenAccount(!isOpenAccount);
  };


  return (
    <>
      <nav className=" w-full bg-white shadow-xl md:w-64 fixed md:top-0 md:bottom-0 md:left-0 z-10 flex flex-col  border border-white items-stretch md:overflow-y-auto md:flex-nowrap py-3 px-4 ">
     <div className=" w-full flex justify-between items-center">
        <a href="/homepage" className="-m-1.5 p-1.5   ">
          <h1 className="flex items-center text-1xl font-bold text-blue-700">
            FinancialPrediction
            <span className="bg-blue-100 text-blue-800 text-base font-semibold me-2 px-2 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ms-1">
              Al
             
            </span>
          </h1>
          
        </a>
        <div>
        <CountryFlag />
        </div>
        <div className="flex justify-end items-end md:hidden  ">
          <button
            onClick={() => setCollapseShow(!collapseShow)}
            className="text-black opacity-50 text-xl px-3 py-1 rounded focus:outline-none align-end"
          >
            {collapseShow ? <FaTimes color={""} /> : <FaBars color={""} />}
          </button>
        </div>
        
        <div className="h-[1px] mt-2  bg-gray-200">
        </div>
        </div>
        <div
        
          className={`transition-all duration-300 ease-in-out ${collapseShow ? "block" : "hidden"} md:block md:flex md:flex-col md:items-stretch text-dark`}>
          <ul className="flex flex-col list-none mt-2">
            <li className="hs-accordion">
              <button
                type="button"
                className="hs-accordion-toggle w-full text-start flex items-center gap-x-3 py-2 px-2.5 text-sm text-gray-700 rounded-lg bg-blue-50 focus:outline-none focus:bg-gray-100 dark:bg-neutral-800 dark:text-neutral-400 dark:hs-accordion-active:text-white dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 dark:focus:text-neutral-300"
                aria-expanded={isOpenAnalysis ? 'true' : 'false'}
                onClick={toggleAccordionAnalysis}
              >
                
                <FaMagnifyingGlassChart />
                Financial Analysis

              </button>

              <div
                id="account-accordion-sub-1-collapse-1"
                className={`hs-accordion-content w-full overflow-hidden transition-[height] duration-300 ${isOpenAnalysis ? 'block' : 'hidden'}`}
                role="region"
                aria-labelledby="account-accordion"
              >
                <ul className="pt-1 ps-7 space-y-1">
                  <li>
                    <TabsRouter
                      changePage={() => setTab(1)}
                      activeTab={tab}
                      setActiveTab={setTab}
                    />
                  </li>

                </ul>
              </div>
            </li>
          </ul>
          <div>
            <ul className="flex flex-col list-none mt-4">
              <li className="hs-accordion">
                <button
                  type="button"
                  className="hs-accordion-toggle w-full text-start flex items-center gap-x-3 py-2 px-2.5 text-sm text-gray-700 rounded-lg bg-blue-50 focus:outline-none focus:bg-gray-100 dark:bg-neutral-800 dark:text-neutral-400 dark:hs-accordion-active:text-white dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 dark:focus:text-neutral-300"
                  aria-expanded={isOpenRequest ? 'true' : 'false'}
                  onClick={toggleAccordionRequest}
                >
                  <FaMoneyCheckDollar />
                  Financial Requests

                  {/* <svg
                    className={`hs-accordion-active:block ms-auto hidden size-4 text-gray-600 ${isOpenRequest ? 'block' : 'hidden'}`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="m18 15-6-6-6 6" />
                  </svg>

                  <svg
                    className={`hs-accordion-active:hidden ms-auto block size-4 text-gray-600 ${!isOpenRequest ? 'block' : 'hidden'}`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="m6 9 6 6 6-6" />
                  </svg> */}
                </button>

                <div
                  id="account-accordion-sub-1-collapse-1"
                  className={`hs-accordion-content w-full overflow-hidden transition-[height] duration-300 ${isOpenRequest ? 'block' : 'hidden'}`}
                  role="region"
                  aria-labelledby="account-accordion"
                >
                  <ul className="pt-1 ps-7 space-y-1">
                    <li>
                      <Link
                        onClick={() => {
                          navigate("/loan");
                        }}
                        className="flex items-center gap-x-3 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-blue-50 focus:outline-none focus:bg-gray-100 dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
                      >
                        <FaHandshake />

                        <span>Loan Request</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          navigate("/equity");
                        }}
                        className="flex items-center gap-x-3 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-blue-50 focus:outline-none focus:bg-gray-100 dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
                      >
                        <PiEqualizer />

                        <span>Equity Request</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <ul className="flex flex-col list-none mt-4">
              <li className="hs-accordion">
                <button
                  type="button"
                  className="hs-accordion-toggle w-full text-start flex items-center gap-x-3 py-2 px-2.5 text-sm text-gray-700 rounded-lg bg-blue-50 focus:outline-none focus:bg-gray-100 dark:bg-neutral-800 dark:text-neutral-400 dark:hs-accordion-active:text-white dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 dark:focus:text-neutral-300"
                  aria-expanded={isOpenAccount ? 'true' : 'false'}
                  onClick={toggleAccordionAccount}
                >
                  <svg
                    className="size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="18" cy="15" r="3" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M10 15H6a4 4 0 0 0-4 4v2" />
                    <path d="m21.7 16.4-.9-.3" />
                    <path d="m15.2 13.9-.9-.3" />
                    <path d="m16.6 18.7.3-.9" />
                    <path d="m19.1 12.2.3-.9" />
                    <path d="m19.6 18.7-.4-1" />
                    <path d="m16.8 12.3-.4-1" />
                    <path d="m14.3 16.6 1-.4" />
                    <path d="m20.7 13.8 1-.4" />
                  </svg>
                  Account


                </button>

                <div
                  id="account-accordion-sub-1-collapse-1"
                  className={`hs-accordion-content w-full overflow-hidden transition-[height] duration-300 ${isOpenAccount ? 'block' : 'hidden'}`}
                  role="region"
                  aria-labelledby="account-accordion"
                >
                  <ul className="pt-1 ps-7 space-y-1">

                    {
                      <>
                        {auth.token ? (
                          <li>
                            <Link
                              onClick={() => {
                                dispatch(logOut());
                                navigate("/homepage");
                                toast.info("Successfully logged out");
                              }}
                              className="flex items-center gap-x-3 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-blue-50 focus:outline-none focus:bg-gray-100 dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
                            >
                              <FaSignOutAlt className="mr-2" />
                              <span>{intl.formatMessage({ id: "NAV.BUTTON.logOut" })}</span>
                            </Link>
                          </li>
                        ) : (
                          <>
                            <li>
                              <Link
                                to="/login"
                                className="flex items-center gap-x-3 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-blue-50 focus:outline-none focus:bg-gray-100 dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
                              >
                                <FaFingerprint className="mr-2" />
                                <span>{intl.formatMessage({ id: "NAV.BUTTON.LOGIN" })}</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/register"
                                className="flex items-center gap-x-3 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-blue-50 focus:outline-none focus:bg-gray-100 dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
                              >
                                <FaUserPlus className="mr-2" />
                                <span>{intl.formatMessage({ id: "NAV.BUTTON.SIGN_UP" })}</span>
                              </Link>
                            </li>
                          </>
                        )}
                      </>
                    }
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
