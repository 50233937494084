import storeConstants from "redux/constants/actionTypes";

const initialState = {
    equity: [],
    addEquity: [],
    saveEquity: false

}

const equityRequest = (state = initialState, action) => {
    switch (action.type) {
        case storeConstants.GET_EQUITY:
            return {
                ...state,
                equity: action.payload,
            }
        case storeConstants.ADD_EQUITY:
            return {
                ...state,
                addEquity: action.payload,
            }
        case storeConstants.SAVE_EQUITY:
            return {
                ...state,
                saveEquity: action.payload,
            }
        default:
            return state
    }
}

export default equityRequest;