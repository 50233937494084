import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';

const indicatorsBackgroundColor = [
  'rgba(255, 99, 132, 0.8)',
  'rgba(54, 162, 235, 0.8)',
  'rgba(255, 206, 86, 0.8)',
  'rgba(75, 192, 192, 0.8)',
  'rgba(153, 102, 255, 0.8)',
  'rgba(255, 159, 64, 0.8)',
];

const HeaderAnalysisChart = () => {
  const intl = useIntl();

  const { rating_valuation, custom_valuation } = useSelector(
    (state) => state.analysisDataReducer.analysisResult
  );

  const chartRef = useRef(null); // Chart.js instance kontrolü

  const RaitingDataBar = rating_valuation
    ? {
        labels: custom_valuation[0]?.value === 'N/A' ? ['Baseline'] : ['Baseline', 'Custom'],
        datasets: [
          {
            label: 'Rating',
            backgroundColor: custom_valuation[0]?.value === 'N/A' ? '#ed64a6' : '#4c51bf',
            barThickness: 8,
            data:
              custom_valuation[0]?.value === 'N/A'
                ? [
                    typeof rating_valuation[0]?.value === 'number'
                      ? rating_valuation[0]?.value.toFixed(2)
                      : 0,
                  ]
                : [
                    typeof rating_valuation[0]?.value === 'number'
                      ? rating_valuation[0]?.value.toFixed(2)
                      : 0,
                    typeof custom_valuation[0]?.value === 'number'
                      ? custom_valuation[0]?.value
                      : 0,
                  ],
          },
        ],
      }
    : null;

  const ValuationDataBar = rating_valuation
    ? {
        labels: custom_valuation[1]?.value === 'N/A' ? ['Baseline'] : ['Baseline', 'Custom'],
        datasets: [
          {
            label: 'Valuation',
            backgroundColor: indicatorsBackgroundColor,
            barThickness: 10,
            data:
              custom_valuation[1]?.value === 'N/A'
                ? [
                    typeof rating_valuation[1]?.value === 'number'
                      ? rating_valuation[1]?.value.toFixed(2)
                      : 0,
                  ]
                : [
                    typeof rating_valuation[1]?.value === 'number'
                      ? rating_valuation[1]?.value.toFixed(2)
                      : 0,
                    typeof custom_valuation[1]?.value === 'number'
                      ? custom_valuation[1]?.value
                      : 0,
                  ],
          },
        ],
      }
    : null;

  const options = {
    maintainAspectRatio: false, // Daha iyi responsive destek
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          maxTicksLimit: 5,
        },
        grid: {
          drawBorder: false,
        },
      },
    },
  };

  useEffect(() => {
    Chart.register(ChartDataLabels);
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, []);

return (
  <div className="mx-auto  pt-2 pb-2 " >
    {rating_valuation ? (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
        <div className="bg-white rounded-lg shadow-xl mx-4 p-4">
          <Bar data={RaitingDataBar} options={{ ...options, aspectRatio: 1.5 }} />
        </div>

        <div className="bg-white rounded-lg shadow-xl mx-4 p-4">
          <Bar data={ValuationDataBar} options={{ ...options, aspectRatio: 1.5 }} />
        </div>
      </div>
    ) : (
      <div className="flex justify-center items-center h-60">
        <p></p>
      </div>
    )}
  </div>
);

};

export default HeaderAnalysisChart;
