import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import * as analysisDataActions from 'redux/actions/analysisDataActions'
import { analysis } from 'redux/ApiCalls'
import Spinner from 'components/Spinner'
import ReactTooltip from 'react-tooltip'
import ProgressTableAndExcelExport from 'components/ProgressTableAndExcelExport'
import CurrencyInfo from 'components/CurrencyInfo'

const FxPosition = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [lastKeyPress, setLastKeyPress] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const {
    firmBackground,
    financial_statements,
    fx_position,
    maturity_short_term,
    business_plan,
    analysisResult,
  } = useSelector((state) => state.analysisDataReducer)
  let { flowOrstock } = firmBackground

  const { token } = useSelector((state) => state.auth)

  // const handleInputChange = (value, key, index) => {
  //   const numberRegex = /[0-9]/g

  //   let newValue = value.replace(/,/g, '')

  //   if (newValue.match(numberRegex)?.length === newValue.length) {
  //     const newFxData = [...fx_position]

  //     const findValue = newFxData[index].values.find((item) => item.key === key)

  //     findValue.value = Number(newValue).toLocaleString('en-US')
  //     dispatch(analysisDataActions.fxPosition(newFxData))
  //     setLastKeyPress(newFxData)
  //   }
  //   else if (newValue.length === 0) {
  //     const newFxData = [...fx_position]

  //     const findValue = newFxData[index].values.find((item) => item.key === key)

  //     findValue.value = 0
  //     dispatch(analysisDataActions.fxPosition(newFxData))
  //     setLastKeyPress(newFxData)
  //   }
  // }

  const handleInputChange = (value, key, index) => {
    const numberRegex = /^-?\d*\.?\d+$/ // Allows negative numbers and decimals

    let newValue = value.replace(/,/g, '')

    if (newValue === '' || newValue.match(numberRegex)) {
      const newFxData = [...fx_position]

      const findValue = newFxData[index].values.find((item) => item.key === key)

      findValue.value =
        newValue !== '' ? Number(newValue).toLocaleString('en-US') : ''
      dispatch(analysisDataActions.fxPosition(newFxData))
      setLastKeyPress(newFxData)
    }
  }

  useEffect(() => {
    if (lastKeyPress) {
      const timeoutId = setTimeout(() => calculateAnalysis(), 1500)
      return () => clearTimeout(timeoutId)
    }
  }, [fx_position, lastKeyPress])

  const trueOrFalse = (() => {
    if (firmBackground.industry === 'Finance & Insurance') {
      return false
    }
    return true
  })()

  const calculateAnalysis = () => {
    setIsLoading(true)

    const postData = {
      firm_background: firmBackground,
      analysis_data: {
        financial: {},
        fx: {},
        maturity: {},
        business_plan: {},
        scenario: {},
      },
      is_analysis_real: trueOrFalse,
      enable_custom_scenario: analysisResult.enable_custom_scenario,
    }

    financial_statements.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.financial[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.financial[value.key].push(newValue)
        })
      }
    })

    fx_position.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.fx[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.fx[value.key].push(newValue)
        })
      }
    })

    maturity_short_term.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.maturity[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.maturity[value.key].push(newValue)
        })
      }
    })

    business_plan.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.business_plan[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.business_plan[value.key].push(newValue)
        })
      }
    })

    if (analysisResult?.scenarios) {
      analysisResult.scenarios.map((item, index) => {
        if (index === 0) {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key] = [newValue]
          })
        } else {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key].push(newValue)
          })
        }
      })
    } else {
      initial_custom_scenario.map((item, index) => {
        if (index === 0) {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key] = [newValue]
          })
        } else {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key].push(newValue)
          })
        }
      })
    }
    analysis(token, postData)
      .then(({ data }) => {
        dispatch(analysisDataActions.fetchRealAnalysis(data))
        setIsLoading(false)
      })
      .catch((err) => err)
  }

  useEffect(() => {
    setIsLoading(false)
  }, [])

  const TableStyle = {
    ThStyle: `min-w-[80px] max-w-[140px] w-[100px] sm:w-[120px] md:w-[110px] lg:w-[140px] xl:w-[130px] p-0 m-0 text-white text-end text-xs `,

    ThStyleHeader: `min-w-[80px] max-w-[140px] w-[100px] sm:w-[120px] md:w-[110px] lg:w-[140px] xl:w-[130px] p-0 m-0 text-black text-end text-xs `,

    ThStyleHeaderText: `min-w-[80px] max-w-[140px] w-[100px] sm:w-[120px] md:w-[110px] lg:w-[140px] xl:w-[130px] p-0 mb-0 text-black text-end text-xs  `,

    ThStyleHeaderLabel: `min-w-[80px] max-w-[140px] w-[100px] sm:w-[120px] md:w-[110px] lg:w-[140px] xl:w-[130px]  p-0 m-0 text-black text-start text-xs `,

    TdStyle: `min-w-[115px] max-w-[140px] w-[100px] sm:w-[120px] md:w-[110px] lg:w-[140px] xl:w-[130px] 
              p-0 m-0 text-end border-l  border-b border-[#d2d5d9] text-xs   `,

    TdLabelStyle: `min-w-[300px] max-w-[300px] w-[300px] sm:w-[300px] md:w-[300px] lg:w-[200px] xl:w-[200px] 
                   p-0 m-0 text-start text-xs    !font-normal !text-sm `,

    TdLabelStyleHeader: `min-w-[200px] max-w-[200px] w-[200px]  sm:w-[200px] md:w-[200px] lg:w-[200px] xl:w-[200px] 
                         p-0 m-0  
                         text-start text-xs  !font-normal !text-sm`,
  };


  const table = (
    <table className="w-full !border border-[#d2d5d9]   rounded-md sm:max-w-full md:max-w-[80%] lg:max-w-[60%] table-auto items-center">

      <thead className="sticky top-0 text-end  z-9">
        <tr >
          <th className={`${TableStyle.ThStyleHeaderText} text-center text-bold bg-white `}>
            <span className="flex">
              {intl.formatMessage({ id: 'FINANCIAL_CHART.CURRENCY' })}{firmBackground.currency_sym}
              <span className="flex ">
                {intl.formatMessage({ id: 'FINANCIAL_CHART.DECIMAL' })}{firmBackground.decimal}
              </span>
            </span>
          </th>
       
        </tr>
      </thead>
      <thead className="sticky top-[20px] text-end  z-9">
        <tr>
          <th className={TableStyle.ThStyleHeaderText}>
            <CurrencyInfo showFirstButton={true} showSecondButton={true} />
          </th>
          <th className={`${TableStyle.ThStyleHeaderText} text-dark text-center`} colSpan={1}>
            <ProgressTableAndExcelExport showFirstButton={true} />
          </th>
          <th className={`${TableStyle.ThStyleHeader} text-black text-end`} colSpan={1}>
            <ProgressTableAndExcelExport showSecondButton={true} />
          </th>
        
        </tr>
      </thead>


      <thead className="text-center w-full bg-primary rounded-md">
        <tr>
        <th className={`${TableStyle.TdLabelStyle} !bg-gray-300 !font-semibold`}>
            Fx Position
          </th>
          {fx_position.map((item, index) => (
            <th key={index} className={TableStyle.ThStyle}>
              {new Date(item.year).toLocaleString('en-US', { month: 'short' }) +
                ' ' +
                new Date(item.year).toLocaleString('en-US', { year: 'numeric' })}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {fx_position[0]?.values.map((row, rowIndex) => (
          <tr key={rowIndex} className={'bg-gray-200'}>
            <td className={`${TableStyle.TdLabelStyle} text-gray-800 font-normal !border-l !border-r  border-b border-[#d2d5d9] `}
              data-tip={row.label === 'Cash And Banks' ? intl.formatMessage({ id: 'tooltip_cash_and_banks' }) : row.label === 'Debt Securities' ? intl.formatMessage({ id: 'tooltip_equity_securities' }) : row.label === 'Equity Securities' ? intl.formatMessage({ id: 'tooltip_equity_securities' }) : row.label === 'Debt' ? intl.formatMessage({ id: 'Debt' }) : row.label === 'Sales Of Goods and Services' ? intl.formatMessage({ id: 'tooltip_sales_of_goods_and_services' }) : row.label === 'Cost Of Sales Of Goods and Services Sold' ? intl.formatMessage({ id: 'tooltip_cost_of_goods_and_services_sold' }) : ''}>
              <ReactTooltip />
              <span className="block truncate">{intl.formatMessage({ id: row.key })}</span>
            </td>
            {fx_position.map((yearData, columnIndex) => (
              <td key={columnIndex} className={`${TableStyle.TdStyle} bg-white text-end   !border-l border-[#d2d5d9]`}>
                <input onChange={(e) => handleInputChange(e.target.value, row.key, columnIndex)}
                  className="w-full border-none text-end text-xxs focus:outline-none focus:ring-2 focus:ring-blue-500 m-0 py-0" type="text" value={yearData.values[rowIndex]?.value.toLocaleString('en-US')} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <>
      {isLoading ? <Spinner /> : ''}
      {table}
    </>
  );

}
export default FxPosition;  