import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import saveAnalysis from 'helpers/saveAnalysis'
import { useDispatch } from 'react-redux'
import storeConstants from 'redux/constants/actionTypes'
import { toast } from 'react-toastify'
import { analysisList } from 'redux/ApiCalls'
import Spinner from 'components/Spinner'

import { useState, useEffect } from 'react'
import * as analysisDataActions from 'redux/actions/analysisDataActions'
import {
  analysis,
  getCustomScenarioList,
  getCustomScenarioDataList,
} from 'redux/ApiCalls'

const AnalysisButtons = ({ activeTab, setActiveTab }) => {
  const intl = useIntl()

  const { analysisListResult, removeOrGetLast } = useSelector(
    (state) => state.analysisDataReducer
  )

  const analysisIdNoYes =
    analysisListResult?.length > 0 && analysisListResult[0].id

  const { analysisResult } = useSelector((state) => state.analysisDataReducer)

  const [removeOrLastButton, setRemoveOrLastButton] = useState(false)

  const custom = analysisResult?.scenarios
  const token = useSelector((state) => state.auth.token)
  const navigate = useNavigate()

  const params = useParams()
  const dispatch = useDispatch()
  const [lastKeyPress, setLastKeyPress] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isLastCustomScenario, setIsLastCustomScenario] = useState(false)

  const {
    firmBackground,
    financial_statements,
    fx_position,
    maturity_short_term,
    business_plan,
    firmSlug,
    initial_custom_scenario,
  } = useSelector((state) => state.analysisDataReducer)


  // let { flowOrstock } = firmBackground

  const { scenarios } = analysisResult
  const firmSlugOrFirmSlugReducer = params?.firmSlug
    ? params?.firmSlug
    : firmSlug
  let year = firmBackground.foreCastStartDate
  let projection_frequency = firmBackground.projection_frequency

  const trueOrFalse = (() => {
    if (firmBackground.industry === 'Finance & Insurance') {
      return false
    }
    return true
  })()

  const calculateAnalysis = (isCustomScenario) => {
    if (analysisResult.enable_custom_scenario !== isCustomScenario) {
      isCustomScenario
        ? dispatch(analysisDataActions.enableCustomScenario())
        : dispatch(analysisDataActions.disableCustomScenario())
    }
    const postData = {
      analysis_data: {
        financial: {},
        fx: {},
        maturity: {},
        business_plan: {},
        scenario: {},
      },
      firm_background: firmBackground,
      is_analysis_real: trueOrFalse,
      enable_custom_scenario: isCustomScenario,
    }

    financial_statements.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.financial[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.financial[value.key].push(newValue)
        })
      }
    })

    fx_position.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.fx[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.fx[value.key].push(newValue)
        })
      }
    })

    maturity_short_term.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.maturity[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.maturity[value.key].push(newValue)
        })
      }
    })

    business_plan.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.business_plan[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.business_plan[value.key].push(newValue)
        })
      }
    })

    if (analysisResult?.scenarios) {
      console.log('i')
      analysisResult.scenarios.map((item, index) => {
        if (index === 0) {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key] = [newValue]
          })
        } else {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key].push(newValue)
          })
        }
      })
    } else {
      initial_custom_scenario.map((item, index) => {
        if (index === 0) {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key] = [newValue]
          })
        } else {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key].push(newValue)
          })
        }
      })
    }

    analysis(token, postData)
      .then(({ data }) => {
        dispatch(analysisDataActions.fetchRealAnalysis(data))
        setIsLoading(false)
      })
      .catch((err) => err)
  }

  const removeCustomScenario = () => {
    setRemoveOrLastButton(false)
    setIsLoading(true)
    setIsLastCustomScenario(false)
    calculateAnalysis(false)
    dispatch({
      type: storeConstants.REMOVE_OR_GET_LAST,
      payload: false,
    })
  }

  const getLastCustom = () => {
    setRemoveOrLastButton(true)
    const postData = {
      analysis_data: {
        financial: {},
        fx: {},
        maturity: {},
        business_plan: {},
        scenario: {},
      },
      firm_background: firmBackground,
      is_analysis_real: trueOrFalse,
      enable_custom_scenario: true,
    }

    financial_statements.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.financial[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.financial[value.key].push(newValue)
        })
      }
    })

    fx_position.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.fx[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.fx[value.key].push(newValue)
        })
      }
    })

    maturity_short_term.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.maturity[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.maturity[value.key].push(newValue)
        })
      }
    })

    business_plan.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.business_plan[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.business_plan[value.key].push(newValue)
        })
      }
    })

    if (scenarios) {
      scenarios.map((item, index) => {
        if (index === 0) {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key] = [newValue]
          })
        } else {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key].push(newValue)
          })
        }
      })
    } else {
      initial_custom_scenario.map((item, index) => {
        if (index === 0) {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key] = [newValue]
          })
        } else {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key].push(newValue)
          })
        }
      })
    }

    if (token && firmSlugOrFirmSlugReducer.length > 0) {
      analysisList(token, firmSlugOrFirmSlugReducer)
        .then(({ data }) => {
          getCustomScenarioList(
            token,
            data.results[0].id,
            projection_frequency
          ).then((res) => {
            let lastCustomDataId = res.data.results.map((k) => k.id).pop()
            if (lastCustomDataId) {
              getCustomScenarioDataList(
                token,
                res.data.results[0].analyze,
                lastCustomDataId,
                projection_frequency,
                year
              )
                .then(({ data }) => {
                  let newScenarios = [...scenarios]
                  data.results.map((item, index) => {
                    let findYearData = newScenarios.find(
                      (x) =>
                        new Date(x.year).toISOString().substring(0, 19) +
                        'Z' ===
                        item.year_for_data
                    )
                    if (findYearData) {
                      Object.keys(item).map((item2, i) => {
                        let findData = findYearData.values.find(
                          (x) => x.key === item2
                        )
                        if (findData) {
                          findData.value = Object.values(item)[i]
                        }
                      })
                    }
                  })
                  console.log(newScenarios, 'newScenarios')
                  dispatch(
                    analysisDataActions.getLastCustomScenarioAction(
                      newScenarios
                    )
                  )
                })
                .catch((err) => {
                  console.log(err)
                })
            }
          })
        })
        .catch((err) => {
          console.log(err)
        })
    }
    setIsLoading(false)
    setIsLastCustomScenario(true)
  }

  const handleSaveButton = () => {
    saveAnalysis(
      token,
      firmBackground,
      financial_statements,
      fx_position,
      maturity_short_term,
      business_plan,
      custom,
      firmSlugOrFirmSlugReducer,
      activeTab,
      analysisIdNoYes
    )
      .then(() => {
        toast.success('Analysis Saved')
        dispatch({
          type: storeConstants.SAVE_ANALYSIS,
          payload: true,
        })
        if (token && firmSlugOrFirmSlugReducer.length > 0) {
          analysisList(token, firmSlugOrFirmSlugReducer)
            .then((res) => {
              dispatch({
                type: storeConstants.ANALYSIS_LIST,
                payload: res.data.results,
              })
            })
            .catch((err) => {
              console.log(err)
            })
        }
      })
      .catch((err) => {
        dispatch({
          type: storeConstants.SAVE_ANALYSIS,
          payload: true,
        })
        err.errorType === 'noToken' ? navigate('/register') : console.log(err)
      })
  }

  useEffect(() => {
    if (isLastCustomScenario) {
      setIsLoading(true)
      const timeoutId = setTimeout(() => {
        dispatch(analysisDataActions.enableCustomScenario())
        calculateAnalysis(true)
      }, 2000)
      return () => clearTimeout(timeoutId)
    }
  }, [isLastCustomScenario])

  useEffect(() => {
    if (lastKeyPress) {
      const timeoutId = setTimeout(() => {
        calculateAnalysis(true)
        setIsLoading(true)
      }, 2000)
      return () => clearTimeout(timeoutId)
    }
  }, [lastKeyPress])

  const reportButtonControl = () => {
    const findData = financial_statements.find((item) =>
      item.values.find((value) => {
        if (isNaN(value.value)) {
          let numberWithoutComma = value.value.replace(/,/g, '')
          return Number(numberWithoutComma) > 0
        } else {
          return value.value > 0
        }
      })
    )

    if (findData) {
      return true
    } else {
      return false
    }
  }

  return (

    <>
      {isLoading && <Spinner />}
      <div >
        {isLoading ? <Spinner /> : ''}
        <div className="flex container mx-auto  pt-2 pb-2 justify-between space-x-4">
  <button
    type="button"
    className="flex-1 bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 flex items-center justify-center"
    onClick={() => setActiveTab(activeTab - 1)}
  >
    <svg className="w-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd" />
    </svg>
    <p className="text-sm">
      {intl.formatMessage({
        id: 'COMPONENT.ANALYSIS_BUTTONS.PREVIOUS',
      })}
    </p>
  </button>

  {/* Save Button */}
  <button
    type="button"
    className="flex-1 bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 flex items-center justify-center"
    onClick={handleSaveButton}
  >
    <span className="text-sm mr-2">
    {activeTab === 1 || activeTab === 2 || activeTab === 3
      ? intl.formatMessage({ id: 'COMPONENT.ANALYSIS_BUTTONS.SAVE' })
      : activeTab === 4
      ? intl.formatMessage({ id: 'COMPONENT.BUSINESS_ANALYSIS_BUTTONS.SAVE' })
      : activeTab === 5
      ? intl.formatMessage({ id: 'COMPONENT.CUSTOM_ANALYSIS_BUTTON.SAVE' })
      : null}
      </span>
  </button>

  {/* Next Button */}
  <button
    onClick={() => setActiveTab(activeTab + 1)}
    disabled={activeTab === 5 && !reportButtonControl()}
    type="button"
    className="flex-1 bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 flex items-center justify-center disabled:opacity-50"
  >
    <span className="text-sm mr-2">
      {intl.formatMessage({ id: 'COMPONENT.ANALYSIS_BUTTONS.NEXT' })}
    </span>
    <svg className="w-5 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
    </svg>
  </button>
</div>

      </div>
    </>

  )
}

export default AnalysisButtons
