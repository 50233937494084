import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as analysisDataActions from '../redux/actions/analysisDataActions'
import { useIntl } from 'react-intl'
import { analysis } from 'redux/ApiCalls'
import Loader from 'components/Loader'
import Spinner from 'components/Spinner'
import CurrencyInfo from 'components/CurrencyInfo'
import FlowStockButtons from 'components/FlowStockButtons '
import ProgressTableAndExcelExport from 'components/ProgressTableAndExcelExport'

const FinancialStatements = ({ isLoading, setIsLoading }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { token } = useSelector((state) => state.auth)
  const tourState = useSelector((state) => state.joyRide)

  const [lastKeyPress, setLastKeyPress] = useState(null)

  const {
    fx_position,
    maturity_short_term,
    business_plan,
    initial_custom_scenario,
    analysisResult,
    firmBackground,
    financial_statements,
  } = useSelector((state) => state.analysisDataReducer)

  let { flowOrstock } = firmBackground




  useEffect(() => {
    const timeoutId = setTimeout(() => calculateAnalysis(), 1500)
    return () => clearTimeout(timeoutId)
  }, [financial_statements, lastKeyPress])

  const trueOrFalse = (() => {
    if (firmBackground.industry === 'Finance & Insurance') {
      return false
    }
    return true
  })()

  const calculateAnalysis = () => {
    setIsLoading(true)
    const postData = {
      firm_background: firmBackground,

      analysis_data: {
        financial: {},
        fx: {},
        maturity: {},
        business_plan: {},
        scenario: {},
      },
      is_analysis_real: trueOrFalse,
      enable_custom_scenario: analysisResult.enable_custom_scenario,
    }

    financial_statements.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.financial[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.financial[value.key].push(newValue)
        })
      }
    })

    fx_position.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.fx[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.fx[value.key].push(newValue)
        })
      }
    })

    maturity_short_term.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.maturity[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.maturity[value.key].push(newValue)
        })
      }
    })

    business_plan.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.business_plan[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.business_plan[value.key].push(newValue)
        })
      }
    })

    if (analysisResult?.scenarios) {
      analysisResult.scenarios.map((item, index) => {
        if (index === 0) {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key] = [newValue]
          })
        } else {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key].push(newValue)
          })
        }
      })
    } else {
      initial_custom_scenario.map((item, index) => {
        if (index === 0) {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key] = [newValue]
          })
        } else {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key].push(newValue)
          })
        }
      })
    }

    analysis(token, postData)
      .then(({ data }) => {
        console.log("data", data)
        dispatch(analysisDataActions.fetchRealAnalysis(data))
        setIsLoading(false)
      })
      .catch((err) => err)
  }

  const getDateDiff = () => {
    const timeDiff =
      firmBackground.foreCastEndDate - firmBackground.foreCastStartDate
    const days = timeDiff / (1000 * 60 * 60 * 24)

    const prejectionDays =
      firmBackground.projection_frequency === 'A'
        ? 365
        : firmBackground.projection_frequency === 'Q'
          ? 90
          : firmBackground.projection_frequency === 'M'
            ? 30
            : firmBackground.projection_frequency === '6M'
              ? 180
              : 1

    const result = Math.floor(days / prejectionDays)

    return new Array(result).fill(0)
  }


  const TableStyle = {
    ThStyle: `min-w-[80px] max-w-[140px] w-[100px] sm:w-[120px] md:w-[110px] lg:w-[140px] xl:w-[130px] p-0 m-0 text-white text-end text-xs `,

    ThStyleHeader: `min-w-[80px] max-w-[140px] w-[100px] sm:w-[120px] md:w-[110px] lg:w-[140px] xl:w-[130px] p-0 m-0 text-black text-end text-xs `,

    ThStyleHeaderText: `min-w-[80px] max-w-[140px] w-[100px] sm:w-[120px] md:w-[110px] lg:w-[140px] xl:w-[130px] p-0 mb-0 text-black text-end text-xs  `,

    ThStyleHeaderLabel: `min-w-[80px] max-w-[140px] w-[100px] sm:w-[120px] md:w-[110px] lg:w-[140px] xl:w-[130px]  p-0 m-0 text-black text-start text-xs `,

    TdStyle: `min-w-[115px] max-w-[140px] w-[100px] sm:w-[120px] md:w-[110px] lg:w-[140px] xl:w-[130px] 
              p-0 m-0 text-end border-l  border-b border-[#d2d5d9] text-xs   `,
    TdLabelStyle: `min-w-[150px] max-w-[150px] w-[150px] sm:min-w-[200px] sm:max-w-[200px] md:w-[300px] lg:w-[200px] xl:w-[200px] 
     p-0 m-0 text-start text-xs !font-normal !text-sm truncate overflow-hidden whitespace-nowrap`,

    TdLabelStyleHeader: `min-w-[200px] max-w-[200px] w-[200px]  sm:w-[200px] md:w-[200px] lg:w-[200px] xl:w-[200px] 
                         p-0 m-0  
                         text-start text-xs  !font-normal !text-sm`,
  };



  const handleInputChange = (value, key, index) => {
    const newFinancialStatements = [...financial_statements]
    const findValue = newFinancialStatements[index].values.find(
      (item) => item.key === key
    )

    findValue.value = value

    dispatch(analysisDataActions.financialStatements(newFinancialStatements))

    setLastKeyPress(newFinancialStatements)
  }
  const formatDate = (year, month) => {
    const date = new Date(Number(year), Number(month) - 1, 1);
    return `${date.toLocaleString('en-US', { month: 'short' })} ${date.toLocaleString('en-US', { year: 'numeric' })}`;
  };

  const renderHeaders = (data, isProjection) => {
    return data?.map((yearData, columnIndex) => {
      const [year, month] = yearData.year.split('-');
      return (
        <th
          key={columnIndex}
          className={`${TableStyle.ThStyle} ${isProjection ? 'bg-primary !text-white' : 'bg-primary !text-white'
            }px-2`}
        >
          {isProjection ? formatDate(year, month) : yearData.year}
        </th>
      );
    });
  };



  const table = (

    <table className="w-full !border border-[#d2d5d9]   rounded-md  table-auto">
      <thead className="sticky top-0 text-end  z-9">
        <tr >
          <th className={`${TableStyle.ThStyleHeaderText} text-center text-bold bg-white `}>
            <span className="flex">
              {intl.formatMessage({ id: 'FINANCIAL_CHART.CURRENCY' })}{firmBackground.currency_sym}
              <span className="flex ">
                {intl.formatMessage({ id: 'FINANCIAL_CHART.DECIMAL' })}{firmBackground.decimal}
              </span>
            </span>
          </th>
          <th colSpan={2} className={`${TableStyle.ThStyleHeaderText} text-dark text-center bg-white text-xs sm:text-sm`}>
            Historical
          </th>
          <th colSpan={analysisResult?.financial_statements?.length || 1} className={`${TableStyle.ThStyleHeader} text-black text-center !bg-gray-200 text-xs sm:text-sm`}>
            Projection
          </th>
        </tr>
      </thead>
      <thead className="sticky top-[20px] text-end  z-9">
        <tr>
          <th className={TableStyle.ThStyleHeaderText}>
            <CurrencyInfo showFirstButton={true} showSecondButton={true} />
          </th>
          <th className={`${TableStyle.ThStyleHeaderText} text-dark text-center`} colSpan={1}>
            <ProgressTableAndExcelExport showFirstButton={true} />
          </th>
          <th className={`${TableStyle.ThStyleHeader} text-black text-end`} colSpan={1}>
            <ProgressTableAndExcelExport showSecondButton={true} />
          </th>
          <th colSpan={analysisResult?.financial_statements?.length || 1} className={`${TableStyle.ThStyleHeader} text-black !bg-gray-200  text-center text-xs sm:text-sm`}>
          </th>
        </tr>
      </thead>
      <thead className="sticky top-[44px] text-center w-full rounded-md bg-gray-100 z-5">
        <tr>
          <th className={`${TableStyle.TdLabelStyle} !bg-gray-300 !font-semibold`}>
            {intl.formatMessage({ id: 'FINANCIAL_STATEMENT.BALANCE_SHEET_TABLE.TITLE' })}
          </th>
          {renderHeaders(financial_statements, true)}
          {renderHeaders(flowOrstock ? analysisResult?.financial_statements : analysisResult?.financial_statements_stock, true)}
        </tr>
      </thead>

      <tbody>
        {financial_statements[0]?.values
          .slice(0, firmBackground.industry === 'Finance & Insurance' ? 14 : 12)
          .map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className={'bg-gray-200'}
            >
              <td
  className={`${TableStyle.TdLabelStyle} text-gray-800 font-normal !border-l border-b border-[#d2d5d9] truncate max-w-[150px] sm:max-w-none`}
  title={intl.formatMessage({ id: `tooltip_${row.key}` })}
              >
                {intl.formatMessage({ id: row.key })}
              </td>
              {financial_statements.map((yearData, columnIndex) => (
                <td key={columnIndex} className={`${TableStyle.TdStyle}`}>
                  <input
                    type="text"
                    onFocus={(e) => {
                      if (e.target.value === '0') {
                        e.target.value = '';
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value === '') {
                        handleInputChange(0, row.key, columnIndex);
                      }
                    }}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/,/g, '');
                      if (!isNaN(newValue)) {
                        handleInputChange(newValue, row.key, columnIndex);
                      }
                    }}
                    value={yearData.values[rowIndex].value
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    placeholder="0"
                    className={`w-full border-none text-end text-xs focus:outline-none focus:ring-2  m-0 py-1 bg-white text-gray-900 px-2 `}
                  />
                </td>
              ))}
              {analysisResult?.financial_statements?.map((yearData, columnIndex) => (
                <td
                  key={columnIndex}
                  className={`${TableStyle.TdStyle} text-end `}
                >
                  <input
                    className={`w-full border-none text-end text-xs focus:outline-none focus:ring-2  !bg-gray-200 m-0 py-1 bg-white text-gray-900 px-2 `}
                    type="text"
                    value={yearData.values[rowIndex]?.value.toLocaleString('en-US')}
                    disabled
                  />
                </td>
              ))}
            </tr>
          ))}
      </tbody>

      <thead className="text-center w-full bg-primary rounded-md">
        <tr>
          <th className={`${TableStyle.TdLabelStyle} !bg-gray-300 !font-semibold `}>
            {intl.formatMessage({ id: 'FINANCIAL_STATEMENT.INCOME_STATEMETNS_TABLE.TITLE' })}
          </th>
          {renderHeaders(financial_statements, false)}
          <></> {renderHeaders(flowOrstock ? analysisResult?.financial_statements : analysisResult?.financial_statements_stock, true)}
        </tr>
      </thead>
      <tbody>
        {financial_statements[0]?.values
          .slice(firmBackground.industry === 'Finance & Insurance' ? 14 : 12)
          .map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className={'bg-gray-200'}
            >
              <td
                className={`${TableStyle.TdLabelStyle} text-gray-800 font-normal !border-l  border-b border-[#d2d5d9]  `}
                title={intl.formatMessage({ id: `tooltip_${row.key}` })}
              >
                {intl.formatMessage({ id: row.key })}
              </td>
              {financial_statements.map((yearData, columnIndex) => (
                <td key={columnIndex} className={`${TableStyle.TdStyle} `}>
                  <input
                    type="text"
                    onFocus={(e) => {
                      if (e.target.value === '0') {
                        e.target.value = '';
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value === '') {
                        handleInputChange(0, row.key, columnIndex);
                      }
                    }}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/,/g, '');
                      if (!isNaN(newValue)) {
                        handleInputChange(newValue, row.key, columnIndex);
                      }
                    }}
                    value={yearData.values[
                      rowIndex +
                      (firmBackground.industry === 'Finance & Insurance' ? 14 : 12)
                    ].value
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    placeholder="0"
                    className={`w-full border-none text-end text-xs focus:outline-none focus:ring-2  m-0 py-1 bg-white text-gray-900  px-2`}
                  />
                </td>
              ))}
              {analysisResult?.financial_statements &&
                analysisResult?.financial_statements.map((yearData, columnIndex) => (
                  <td
                    key={columnIndex}
                    className={`${TableStyle.TdStyle} text-end`}
                  >
                    <input
                      className={`w-full border-none text-end text-xs focus:outline-none focus:ring-2  !bg-gray-200  m-0 py-1 bg-white text-gray-900 px-2`}
                      type="text"
                      value={yearData.values[rowIndex]?.value.toLocaleString('en-US')}
                      disabled
                    />
                  </td>
                ))}
            </tr>
          ))}
      </tbody>
    </table>
  );



  return (
    <>
      {isLoading ? <Spinner /> : null}

      {flowOrstock === true ? (
        table
      ) : null}

      {flowOrstock === false ? (
        table
      ) : null}
    </>

  );


}
export default FinancialStatements
