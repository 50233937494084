import { useEffect, useState } from 'react'
import '../assets/styles/components/button/scroll.scss'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
const ScrollToTop = () => {
  // The back-to-top button is hidden at the beginning
  const [showButton, setShowButton] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 300) {
        setShowButton(true)
      } else {
        setShowButton(false)
      }
    })
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // for smoothly scrolling
    })
  }

  return (
    <>
      {showButton && (
        <button onClick={scrollToTop} className="back-to-top">
          <KeyboardDoubleArrowUpIcon />
        </button>
      )}
      {/* &#8679; is used to create the upward arrow */}
    </>
  )
}

export default ScrollToTop
