import { BrowserRouter, Routes, Route } from 'react-router-dom'

// components
import { AppLayout } from 'components/layout'
import PrivateRoute from 'helpers/PrivateRoute'

// pages
import HomePage from 'pages/HomePage'
import MyFirm from 'pages/MyFirm'
import Login from 'pages/Login'
import Register from 'pages/Register'
import AnalysisPage from 'pages/AnalysisPage'
import MyFirmUpdate from 'pages/MyFirmUpdate'
import ResetPassword from 'pages/ResetPassword'
import PasswordResetConfirm from 'pages/PasswordResetConfirm'
import PasswordResetComplete from 'pages/PasswordResetComplete'
import LoanRequest from 'pages/LoanRequest'
import Loan from 'pages/Loan'
import EquityRequest from 'pages/EquityRequest'
import Equity from 'pages/Equity'
import BaselineAndCustomChart from 'components/BaselineAndCustomChart'
import CustomDatePicker from 'components/CustomDatePicker '
import Tables from 'pages/Tables'
// import "@fortawesome/fontawesome-free/css/all.min.css";
import "../../src/assets/styles/tailwind.scss";



const Router = () => {
  return (
    
    <BrowserRouter>
   

      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route
            index
            element={
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            }
          />
          <Route
            path="myfirm"
            element={
              <PrivateRoute>
                <MyFirm />
              </PrivateRoute>
            }
          />
          <Route path="tables" element={<Tables />} />
          <Route path="homepage" element={<HomePage />} />
          <Route path="myfirm-update" element={<MyFirmUpdate />} />

          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="reset" element={<ResetPassword />} />
          <Route path="password-reset-confirm" element={<PasswordResetConfirm />} />
          <Route path="password-reset-complete" element={<PasswordResetComplete />} />

          <Route path="analysis/:firmSlug" element={<AnalysisPage />} />
          <Route path="analysis" element={<AnalysisPage />} />

          {/* Loan */}
          <Route path="get-loan" element={<LoanRequest />} />
          <Route path="loan" element={<Loan />} />
          <Route path="loan-detail/:loanId" element={<LoanRequest />} />
          <Route path="chart" element={<BaselineAndCustomChart />} />

          {/* Equity */}
          <Route path="get-equity" element={<EquityRequest />} />
          <Route path="equity" element={<Equity />} />
          <Route path="equity-detail/:equityId" element={<EquityRequest />} />
          <Route path="custom" element={<CustomDatePicker />} />


        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Router
