import axios from 'axios';

const { default: storeConstants } = require('redux/constants/actionTypes')

export const reset_password = (email, redirect_url) => async dispatch => {
    console.log("calıst")
    const config = {
        headers: {
            'Content-Type': 'application/json',
        }

    };

    const body = JSON.stringify({ email, redirect_url });

    console.log(body, 43)

    try {
        await axios.post(`${process.env.REACT_APP_REST_URL}/user/request-reset-email/`, body, config);
    } catch (err) {
        console.log(err)

    }
};

export const reset_password_confirm = ({ password, uidb64, token }) => async dispatch => {
    console.log("calıst")
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    // const body = JSON.stringify({ password });

    try {
        console.log("calıst")
        await axios.post(`${process.env.REACT_APP_REST_URL}/auth/user/reset_password_sent/${uidb64}/${token}`, password, config);

        dispatch({
            // type: storeConstants.PASSWORD_RESET_CONFIRM_SUCCESS
        });
    } catch (err) {
        // dispatch({
        //     type: storeConstants.PASSWORD_RESET_CONFIRM_FAIL
        // });
    }
};


export const logOut = () => {
    return {
        type: storeConstants.RESET_TOKEN,
        payload: null,
    }
}
