import * as React from 'react'
import { alpha } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Testimonials from './Testimonials'
import Features from './Features'
import Footers from './Footers'
import Highlights from './Highlights'
import { useIntl } from 'react-intl'

export default function Hero() {
  const intl = useIntl()

  return (
    <Box
      id="home-header-box"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 50%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 4, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
        className="home-header-box-container"
      >
        <Stack
          className="home-header-box-stack"
          spacing={2}
          useFlexGap
          sx={{ width: { xs: '100%', sm: '70%' } }}
        >
          <Typography
            component="h1"
            variant="h1"
            className="home-header-box-title one"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              color: '#1976d2',
            }}
          >
            {intl.formatMessage({
              id: 'HOME.TITLE',
            })}
            &nbsp;
            <Typography
              className="home-header-box-title two"
              component="span"
              variant="h1"
              color="white"
            >
              {intl.formatMessage({
                id: 'HOME.TITLE2',
              })}
            </Typography>
          </Typography>
          <Typography
            className="home-header-box-text"
            variant="body1"
            textAlign="center"
            color="text.secondary"
          >
            {intl.formatMessage({
              id: 'HOME.TEXT',
            })}
          </Typography>

          <Button
            className="start_now_button"
            href="/analysis"
            variant="contained"
            color="primary"
          >
            {intl.formatMessage({
              id: 'HOME.HREF.BUTTON',
            })}
          </Button>
        </Stack>
        {/* <Box
          id="image"
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: 'center',
            height: { xs: 200, sm: 700 },
            width: '100%',
            backgroundImage:
              theme.palette.mode === 'light'
                ? 'url("/static/images/templates/templates-images/hero-light.png")'
                : 'url("/static/images/templates/templates-images/hero-dark.png")',
            backgroundSize: 'cover',
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor:
              theme.palette.mode === 'light'
                ? alpha('#BFCCD9', 0.5)
                : alpha('#9CCCFC', 0.1),
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
          })}
        > */}

        <Highlights />
        {/* </Box> */}
      </Container>
    </Box>
  )
}
