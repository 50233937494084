
import { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2"; // ChartJS library
import { useSelector } from "react-redux"; // To access Redux state
import { useIntl } from 'react-intl'
import styles from 'assets/styles/pages/report.module.scss'
// Register chart elements
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register chartJS elements
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const RealAnalysisReport = () => {
  const [selectedMetrics, setSelectedMetrics] = useState([
    "Growth Rate Of Revenues",
    "Gross Margin",
    "EBITDA Margin",
    "Profit Margin",
    "ICR",
    "DSCR",
    "ROA",
    "ROE",
    "Leverage Ratio",
  ]);

  const availableMetrics = [
    "Growth Rate Of Revenues",
    "Gross Margin",
    "EBITDA Margin",
    "Profit Margin",
    "ICR",
    "DSCR",
    "ROA",
    "ROE",
    "Leverage Ratio",
  ];

  const { analysisResult } = useSelector((state) => state.analysisDataReducer);
  const intl = useIntl()
  const { custom_financial_statements, financial_statements, enable_custom_scenario } = analysisResult;
  const labels = financial_statements.map((data) => data.year); // Extract years for the x-axis labels

  const [chartIndicatorsDatas, setChartIndicatorsDatas] = useState({});

  useEffect(() => {
    indicatorsTables([
      "Growth Rate Of Revenues",
      "Gross Margin",
      "EBITDA Margin",
      "Profit Margin",
      "ICR",
      "DSCR",
      "ROA",
      "ROE",
      "Leverage Ratio",
    ]);
  }, []);

  const indicatorsTables = (keyArrays) => {
    let newChartData = {};

    keyArrays.forEach((key) => {
      const dataSet = [];

      const baselineDataSet = {
        backgroundColor: "rgba(75, 192, 192, 0.2)", // Chart color
        label: "Baseline Scenario",
        data: [],
      };
      const customDataSet = {
        backgroundColor: "rgba(153, 102, 255, 0.2)", // Custom scenario color
        label: "Custom Scenario",
        data: [],
      };

      // Fetch baseline data from financial statements
      analysisResult.indicators.forEach((datas) => {
        const indicatorsData = datas.values.find((item) => item.label === key);
        if (indicatorsData) baselineDataSet.data.push(indicatorsData.value);
      });
      dataSet.push(baselineDataSet);

      // Add custom scenario data if enabled
      if (enable_custom_scenario) {
        analysisResult.custom_indicators.forEach((datas) => {
          const indicatorsData = datas.values.find((item) => item.label === key);
          if (indicatorsData) customDataSet.data.push(indicatorsData.value);
        });
        dataSet.push(customDataSet);
      }

      newChartData[key] = { labels, datasets: dataSet };
    });

    setChartIndicatorsDatas(newChartData);
  };

  const handleCheckboxChange = (metric) => {
    setSelectedMetrics((prevSelected) =>
      prevSelected.includes(metric)
        ? prevSelected.filter((item) => item !== metric)
        : [...prevSelected, metric]
    );
  };

  const handleSelectAll = () => {
    setSelectedMetrics(availableMetrics);
  };

  const handleDeselectAll = () => {
    setSelectedMetrics([]);
  };

  const createChartOptions = (titleText) => {
    return {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: titleText,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };
  };

  const table = (
    <>
      <table className="table-auto w-full border border-gray-200">
        <thead className="bg-gray-300">
          <tr>
            <th className="
              pl-1 text-left text-blue-900 font-bold border-b border-gray-200
              min-w-[60px] max-w-[140px]
              xs:min-w-[60px] xs:max-w-[80px]
              sm:min-w-[80px] sm:max-w-[100px]
              md:min-w-[100px] md:max-w-[120px]
              lg:min-w-[100px] lg:max-w-[100px]
              xl:min-w-[120px] xl:max-w-[120px]
              2xl:min-w-[220px] 2xl:max-w-[220px]
              xs:text-[10px] sm:text-xs md:text-sm lg:text-base
                   sticky left-0 bg-gray-300 z-30

            ">
              {intl.formatMessage({ id: 'FINANCIAL_STATEMENT.BALANCE_SHEET_TABLE.TITLE' })}

            </th>

            {analysisResult?.financial_statements?.map(
              (yearData, columnIndex) => {
                yearData.year.split('-')
                const date = new Date(
                  Number(yearData.year.split('-')[0]),
                  Number(yearData.year.split('-')[1]),
                  0
                )
                return (
                  <th scope="col" key={columnIndex} className="
                  pl-1 pr-1 text-end text-gray-700 font-medium border-b border-gray-200
                  min-w-[60px] max-w-[140px]
                  xs:min-w-[60px] xs:max-w-[80px]
                  sm:min-w-[80px] sm:max-w-[100px]
                  md:min-w-[100px] md:max-w-[120px]
                  lg:min-w-[100px] lg:max-w-[100px]
                  xl:min-w-[120px] xl:max-w-[120px]
                  2xl:min-w-[100px] 2xl:max-w-[100px]
                  xs:text-[10px] sm:text-xs md:text-sm lg:text-base
                ">
                    {date.toLocaleString('en-US', { month: 'short' }) +
                      ' ' +
                      date.toLocaleString('en-US', { year: 'numeric' })}
                  </th>
                )
              }
            )}
            <th className="font-medium border-b border-gray-200 lg:w-[60%]"></th>

          </tr>
        </thead>
        <tbody>
          {financial_statements[0]?.values.slice(0, 11).map((row, rowIndex) => (
            <tr key={rowIndex} className="hover:bg-gray-50">
              <td
                className={`
                pr-1 pl-1
                 sticky left-0 bg-white z-10
                 text-gray-800 font-medium border-b
                min-w-[60px] max-w-[140px]
                xs:min-w-[60px] xs:max-w-[80px]
                sm:min-w-[80px] sm:max-w-[100px]
                md:min-w-[100px] md:max-w-[120px]
                lg:min-w-[100px] lg:max-w-[100px]
                xl:min-w-[120px] xl:max-w-[120px]
                2xl:min-w-[100px] 2xl:max-w-[100px]
                xs:text-[10px] sm:text-xs md:text-sm lg:text-base
                ${row.label === 'Total Assets' || row.label === 'Total Liabilities And Equity'
                    ? 'font-bold'
                    : ''}
              `}
                title={intl.formatMessage({ id: `tooltip_${row.key}` })}

              >
                <span className="block truncate">
                  {intl.formatMessage({ id: row.key })}
                </span>
              </td>
              {analysisResult?.financial_statements?.map(
                (yearData, columnIndex) => (
                  <td key={columnIndex} className="
                    pl-1 pr-1 border-b
                    min-w-[60px] max-w-[140px]
                    xs:min-w-[60px] xs:max-w-[80px]
                    sm:min-w-[80px] sm:max-w-[100px]
                    md:min-w-[100px] md:max-w-[120px]
                    lg:min-w-[100px] lg:max-w-[100px]
                    xl:min-w-[80px] xl:max-w-[80px]
                    2xl:min-w-[100px] 2xl:max-w-[100px]
                  ">
                    <input
                      type="text"
                      defaultValue={0}
                      value={yearData.values[rowIndex].value}
                      disabled
                      className="
                        w-full pl-1 text-end border border-gray-300 rounded
                        xs:text-[10px] sm:text-xs md:text-sm lg:text-base
                        focus:outline-none focus:ring-2 focus:ring-blue-500
                      "
                    />
                  </td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <table className="table-auto w-full border border-gray-200">
        <thead className="bg-gray-300">
          <tr>
            <th className="
              pl-1 text-left text-blue-900 font-bold border-b border-gray-200
              min-w-[60px] max-w-[140px]
              xs:min-w-[60px] xs:max-w-[80px]
              sm:min-w-[80px] sm:max-w-[100px]
              md:min-w-[100px] md:max-w-[120px]
              lg:min-w-[100px] lg:max-w-[100px]
              xl:min-w-[120px] xl:max-w-[120px]
              2xl:min-w-[220px] 2xl:max-w-[220px]
              xs:text-[10px] sm:text-xs md:text-sm lg:text-base
                   sticky left-0 bg-gray-300 z-30

            ">
              {intl.formatMessage({
                id: 'FINANCIAL_STATEMENT.INCOME_STATEMETNS_TABLE.TITLE',
              })}

            </th>
            {analysisResult?.financial_statements?.map(
              (yearData, columnIndex) => {
                yearData.year.split('-')
                const date = new Date(
                  Number(yearData.year.split('-')[0]),
                  Number(yearData.year.split('-')[1]),
                  0
                )
                return (
                  <th scope="col" key={columnIndex} className="
                  pl-1 pr-1 text-end text-gray-700 font-medium border-b border-gray-200
                  min-w-[60px] max-w-[140px]
                  xs:min-w-[60px] xs:max-w-[80px]
                  sm:min-w-[80px] sm:max-w-[100px]
                  md:min-w-[100px] md:max-w-[120px]
                  lg:min-w-[100px] lg:max-w-[100px]
                  xl:min-w-[120px] xl:max-w-[120px]
                  2xl:min-w-[100px] 2xl:max-w-[100px]
                  xs:text-[10px] sm:text-xs md:text-sm lg:text-base
                ">
                    {date.toLocaleString('en-US', { month: 'short' }) +
                      ' ' +
                      date.toLocaleString('en-US', { year: 'numeric' })}
                  </th>
                )
              }
            )}
                        <th className="font-medium border-b border-gray-200 lg:w-[60%]"></th>

          </tr>
        </thead>
        <tbody>
          {financial_statements[0]?.values
            .slice(12, financial_statements[0]?.values.length)
            .map((row, rowIndex) => (
              <tr key={rowIndex} className="hover:bg-gray-50">
                <td
                  className={`
                  pr-1 pl-1
                   sticky left-0 bg-white z-10
                   text-gray-800 font-medium border-b
                  min-w-[60px] max-w-[140px]
                  xs:min-w-[60px] xs:max-w-[80px]
                  sm:min-w-[80px] sm:max-w-[100px]
                  md:min-w-[100px] md:max-w-[120px]
                  lg:min-w-[100px] lg:max-w-[100px]
                  xl:min-w-[120px] xl:max-w-[120px]
                  2xl:min-w-[100px] 2xl:max-w-[100px]
                  xs:text-[10px] sm:text-xs md:text-sm lg:text-base
                  ${row.label === 'Total Assets' || row.label === 'Total Liabilities And Equity'
                      ? 'font-bold'
                      : ''}
                `}
                  title={intl.formatMessage({ id: `tooltip_${row.key}` })}
                >
                  <span className="block truncate">
                    {intl.formatMessage({ id: row.key })}
                  </span>
                </td>
                {analysisResult?.financial_statements?.map(
                  (yearData, columnIndex) => (
                    <>
                      <td key={columnIndex} className="
                    pl-1 pr-1 border-b
                    min-w-[60px] max-w-[140px]
                    xs:min-w-[60px] xs:max-w-[80px]
                    sm:min-w-[80px] sm:max-w-[100px]
                    md:min-w-[100px] md:max-w-[120px]
                    lg:min-w-[100px] lg:max-w-[100px]
                    xl:min-w-[80px] xl:max-w-[80px]
                    2xl:min-w-[100px] 2xl:max-w-[100px]
                  ">
                        <input
                          type="text"
                          defaultValue={0}
                          value={yearData?.values[rowIndex + 12].value}
                          disabled
                          className="
                          w-full pl-1 text-end border border-gray-300 rounded
                          xs:text-[10px] sm:text-xs md:text-sm lg:text-base
                          focus:outline-none focus:ring-2 focus:ring-blue-500
                        "
                        />
                      </td>
                    </>
                  )
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </>
  )

  const customTable = custom_financial_statements[0]?.values && (
    <>
      <table className="table-auto w-full border border-gray-200">
        <thead className="bg-gray-300">
          <tr >
            <th className="
              pl-1 text-left text-blue-900 font-bold border-b border-gray-200
              min-w-[60px] max-w-[140px]
              xs:min-w-[60px] xs:max-w-[80px]
              sm:min-w-[80px] sm:max-w-[100px]
              md:min-w-[100px] md:max-w-[120px]
              lg:min-w-[100px] lg:max-w-[100px]
              xl:min-w-[120px] xl:max-w-[120px]
              2xl:min-w-[220px] 2xl:max-w-[220px]
              xs:text-[10px] sm:text-xs md:text-sm lg:text-base
                   sticky left-0 bg-gray-300 z-30

            ">

              {intl.formatMessage({
                id: 'FINANCIAL_STATEMENT.BALANCE_SHEET_TABLE.TITLE',
              })}
            </th>
            {custom_financial_statements?.map((yearData, columnIndex) => {
              yearData.year.split('-')
              const date = new Date(
                Number(yearData.year.split('-')[0]),
                Number(yearData.year.split('-')[1]),
                0
              )
              return (
                <th scope="col" key={columnIndex} className="
                pl-1 pr-1 text-end text-gray-700 font-medium border-b border-gray-200
                min-w-[60px] max-w-[140px]
                xs:min-w-[60px] xs:max-w-[80px]
                sm:min-w-[80px] sm:max-w-[100px]
                md:min-w-[100px] md:max-w-[120px]
                lg:min-w-[100px] lg:max-w-[100px]
                xl:min-w-[120px] xl:max-w-[120px]
                2xl:min-w-[100px] 2xl:max-w-[100px]
                xs:text-[10px] sm:text-xs md:text-sm lg:text-base
              ">
                  {date.toLocaleString('en-US', { month: 'short' }) +
                    ' ' +
                    date.toLocaleString('en-US', { year: 'numeric' })}
                </th>
              )
            })}
            <th className="font-medium border-b border-gray-200 lg:w-[60%]"></th>

          </tr>
        </thead>
        <tbody>
          {custom_financial_statements[0]?.values
            .slice(0, 11)
            .map((row, rowIndex) => (
              <tr key={rowIndex} className="hover:bg-gray-50">
                <td
                  className={`
                        pr-1 pl-1
                         sticky left-0 bg-white z-10
                         text-gray-800 font-medium border-b
                        min-w-[60px] max-w-[140px]
                        xs:min-w-[60px] xs:max-w-[80px]
                        sm:min-w-[80px] sm:max-w-[100px]
                        md:min-w-[100px] md:max-w-[120px]
                        lg:min-w-[100px] lg:max-w-[100px]
                        xl:min-w-[120px] xl:max-w-[120px]
                        2xl:min-w-[100px] 2xl:max-w-[100px]
                        xs:text-[10px] sm:text-xs md:text-sm lg:text-base
                        ${row.label === 'Total Assets' || row.label === 'Total Liabilities And Equity'
                      ? 'font-bold'
                      : ''}
                      `}
                  title={intl.formatMessage({ id: `tooltip_${row.key}` })}

                >
                  <span className="block truncate">
                    {intl.formatMessage({ id: row.key })}
                  </span>
                </td>
                {analysisResult?.custom_financial_statements?.map(
                  (yearData, columnIndex) => (
                    <td key={columnIndex} className="
                    pl-1 pr-1 border-b
                    min-w-[60px] max-w-[140px]
                    xs:min-w-[60px] xs:max-w-[80px]
                    sm:min-w-[80px] sm:max-w-[100px]
                    md:min-w-[100px] md:max-w-[120px]
                    lg:min-w-[100px] lg:max-w-[100px]
                    xl:min-w-[80px] xl:max-w-[80px]
                    2xl:min-w-[100px] 2xl:max-w-[100px]
                  ">
                      <input
                        type="text"
                        defaultValue={0}
                        value={yearData.values[rowIndex].value}
                        disabled
                        className="
                          w-full pl-1 text-end border border-gray-300 rounded
                          xs:text-[10px] sm:text-xs md:text-sm lg:text-base
                          focus:outline-none focus:ring-2 focus:ring-blue-500
                        "
                      />
                    </td>
                  )
                )}
              </tr>
            ))}
        </tbody>
      </table>
      <table className="table-auto w-full border border-gray-200">
        <thead className="bg-gray-300">
          <tr>
            <th className="
              pl-1 text-left text-blue-900 font-bold border-b border-gray-200
              min-w-[60px] max-w-[140px]
              xs:min-w-[60px] xs:max-w-[80px]
              sm:min-w-[80px] sm:max-w-[100px]
              md:min-w-[100px] md:max-w-[120px]
              lg:min-w-[100px] lg:max-w-[100px]
              xl:min-w-[120px] xl:max-w-[120px]
              2xl:min-w-[220px] 2xl:max-w-[220px]
              xs:text-[10px] sm:text-xs md:text-sm lg:text-base
                   sticky left-0 bg-gray-300 z-30

            ">
              <span style={{ color: '#012169' }}>
                {intl.formatMessage({
                  id: 'FINANCIAL_STATEMENT.INCOME_STATEMETNS_TABLE.TITLE',
                })}
              </span>
            </th>
            {custom_financial_statements?.map((yearData, columnIndex) => {
              yearData.year.split('-')
              const date = new Date(
                Number(yearData.year.split('-')[0]),
                Number(yearData.year.split('-')[1]),
                0
              )
              return (
                <th scope="col" key={columnIndex} className="
                pl-1 pr-1 text-end text-gray-700 font-medium border-b border-gray-200
                min-w-[60px] max-w-[140px]
                xs:min-w-[60px] xs:max-w-[80px]
                sm:min-w-[80px] sm:max-w-[100px]
                md:min-w-[100px] md:max-w-[120px]
                lg:min-w-[100px] lg:max-w-[100px]
                xl:min-w-[120px] xl:max-w-[120px]
                2xl:min-w-[100px] 2xl:max-w-[100px]
                xs:text-[10px] sm:text-xs md:text-sm lg:text-base
              ">
                  {date.toLocaleString('en-US', { month: 'short' }) +
                    ' ' +
                    date.toLocaleString('en-US', { year: 'numeric' })}
                </th>
              )
            })}
            <th className="font-medium border-b border-gray-200 lg:w-[60%]"></th>

          </tr>
        </thead>
        <tbody>
          {custom_financial_statements[0]?.values
            .slice(12, custom_financial_statements[0].values.length)
            .map((row, rowIndex) => (
              <tr key={rowIndex} className="hover:bg-gray-50">
                <td className={`
                pr-1 pl-1
                 sticky left-0 bg-white z-10
                 text-gray-800 font-medium border-b
                min-w-[60px] max-w-[140px]
                xs:min-w-[60px] xs:max-w-[80px]
                sm:min-w-[80px] sm:max-w-[100px]
                md:min-w-[100px] md:max-w-[120px]
                lg:min-w-[100px] lg:max-w-[100px]
                xl:min-w-[120px] xl:max-w-[120px]
                2xl:min-w-[100px] 2xl:max-w-[100px]
                xs:text-[10px] sm:text-xs md:text-sm lg:text-base
                ${row.label === 'Total Assets' || row.label === 'Total Liabilities And Equity'
                    ? 'font-bold'
                    : ''}
              `} title={intl.formatMessage({ id: `tooltip_${row.key}` })}
                >
                  <span className="block truncate">
                    {intl.formatMessage({ id: row.key })}
                  </span>
                </td>
                {analysisResult?.custom_financial_statements?.map(
                  (yearData, columnIndex) => (
                    <>
                      <td key={columnIndex} className="
                    pl-1 pr-1 border-b
                    min-w-[60px] max-w-[140px]
                    xs:min-w-[60px] xs:max-w-[80px]
                    sm:min-w-[80px] sm:max-w-[100px]
                    md:min-w-[100px] md:max-w-[120px]
                    lg:min-w-[100px] lg:max-w-[100px]
                    xl:min-w-[80px] xl:max-w-[80px]
                    2xl:min-w-[100px] 2xl:max-w-[100px]
                  ">
                        <input
                          type="text"
                          defaultValue={0}
                          value={yearData?.values[rowIndex + 12].value}
                          disabled
                          className="
                          w-full pl-1 text-end border border-gray-300 rounded
                          xs:text-[10px] sm:text-xs md:text-sm lg:text-base
                          focus:outline-none focus:ring-2 focus:ring-blue-500
                        "
                        />
                      </td>
                    </>
                  )
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </>
  )


  return (
    <div>
      <div className={styles.filters}>
        <div className={styles.filters_charts}>
          <button onClick={handleSelectAll}>Tümünü Seç
          </button>
          <button onClick={handleDeselectAll}>Tümünün Seçimini Kaldır</button>
        </div>

        <div className={styles.checkbox_group}>
          {availableMetrics.map((metric) => (
            <div key={metric}>
              <input
                type="checkbox"
                id={metric}
                checked={selectedMetrics.includes(metric)}
                onChange={() => handleCheckboxChange(metric)}
              />
              <label htmlFor={metric}>{metric}</label>
            </div>
          ))}
        </div>
      </div>

      <div className="row justify-content-center mt-5">
        {selectedMetrics.includes("Growth Rate Of Revenues") &&
          chartIndicatorsDatas["Growth Rate Of Revenues"] && (
            <div className="col-12 col-md-6 mb-4">
              <Bar
                options={createChartOptions("Growth Rate Of Revenues")}
                data={chartIndicatorsDatas["Growth Rate Of Revenues"]}
              />
            </div>
          )}
        {selectedMetrics.includes("Growth Rate Of Revenues") && (
          <hr style={{ borderTop: "2px solid black", width: "100%" }} />
        )}

        {selectedMetrics.includes("Gross Margin") &&
          chartIndicatorsDatas["Gross Margin"] && (
            <div className="col-12 col-md-6 mb-4">
              <Bar
                options={createChartOptions("Gross Margin")}
                data={chartIndicatorsDatas["Gross Margin"]}
              />
            </div>
          )}
        {selectedMetrics.includes("Gross Margin") && (
          <hr style={{ borderTop: "2px solid black", width: "100%" }} />
        )}

        {selectedMetrics.includes("EBITDA Margin") &&
          chartIndicatorsDatas["EBITDA Margin"] && (
            <div className="col-12 col-md-6 mb-4">
              <Bar
                options={createChartOptions("EBITDA Margin")}
                data={chartIndicatorsDatas["EBITDA Margin"]}
              />
            </div>
          )}
        {selectedMetrics.includes("EBITDA Margin") && (
          <hr style={{ borderTop: "2px solid black", width: "100%" }} />
        )}

        {selectedMetrics.includes("Profit Margin") &&
          chartIndicatorsDatas["Profit Margin"] && (
            <div className="col-12 col-md-6 mb-4">
              <Bar
                options={createChartOptions("Profit Margin")}
                data={chartIndicatorsDatas["Profit Margin"]}
              />
            </div>
          )}
        {selectedMetrics.includes("Profit Margin") && (
          <hr style={{ borderTop: "2px solid black", width: "100%" }} />
        )}

        {selectedMetrics.includes("ICR") &&
          chartIndicatorsDatas["ICR"] && (
            <div className="col-12 col-md-6 mb-4">
              <Bar options={createChartOptions("ICR")} data={chartIndicatorsDatas["ICR"]} />
            </div>
          )}
        {selectedMetrics.includes("ICR") && (
          <hr style={{ borderTop: "2px solid black", width: "100%" }} />
        )}

        {selectedMetrics.includes("DSCR") &&
          chartIndicatorsDatas["DSCR"] && (
            <div className="col-12 col-md-6 mb-4">
              <Bar options={createChartOptions("DSCR")} data={chartIndicatorsDatas["DSCR"]} />
            </div>
          )}
        {selectedMetrics.includes("DSCR") && (
          <hr style={{ borderTop: "2px solid black", width: "100%" }} />
        )}

        {selectedMetrics.includes("ROA") &&
          chartIndicatorsDatas["ROA"] && (
            <div className="col-12 col-md-6 mb-4">
              <Bar options={createChartOptions("ROA")} data={chartIndicatorsDatas["ROA"]} />
            </div>
          )}
        {selectedMetrics.includes("ROA") && (
          <hr style={{ borderTop: "2px solid black", width: "100%" }} />
        )}

        {selectedMetrics.includes("ROE") &&
          chartIndicatorsDatas["ROE"] && (
            <div className="col-12 col-md-6 mb-4">
              <Bar options={createChartOptions("ROE")} data={chartIndicatorsDatas["ROE"]} />
            </div>
          )}
        {selectedMetrics.includes("ROE") && (
          <hr style={{ borderTop: "2px solid black", width: "100%" }} />
        )}

        {selectedMetrics.includes("Leverage Ratio") &&
          chartIndicatorsDatas["Leverage Ratio"] && (
            <div className="col-12 col-md-6 mb-4">
              <Bar
                options={createChartOptions("Leverage Ratio")}
                data={chartIndicatorsDatas["Leverage Ratio"]}
              />
            </div>
          )}
        {selectedMetrics.includes("Leverage Ratio") && (
          <hr style={{ borderTop: "2px solid black", width: "80%" }} />
        )}
      </div>


      <div className="analysis-table-header-container-sub-text">
        <div
          className={`${styles.report_container_main_header_common
            } ${'col-12 text-left mt-5 mb-3'}`}
          style={{ width: '98%' }}
        >
          III.{' '}
          {intl.formatMessage({
            id: 'FINANCIAL_CHART.TITLE',
          })}
        </div>
      </div>
      {table}
      <br />
      <div className="analysis-table-header-container-sub">
        <div
          className="analysis-table-header-container-sub-text"
          style={{ width: '100%' }}
        >
          {custom_financial_statements[0]?.values && (
            <div
              className={`${styles.report_container_main_header_common
                } ${'col-12 text-left mt-5 mb-3'}`}
              style={{ width: '98%' }}
            >
              IV.{' '}
              {intl.formatMessage({
                id: 'FINANCIAL_CHART.CUSTOM_TABLE_TITLE',
              })}
            </div>
          )}
        </div>
      </div>
      {customTable}
    </div>
  );
};

export default RealAnalysisReport;