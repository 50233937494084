import React, { useState } from 'react';
import { resetPassword } from 'redux/ApiCalls';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
const theme = createTheme();

const ResetPassword = () => {
    const intl = useIntl()
    const [formData, setFormData] = useState({
        email: ''
    });

    const [show, setShow] = useState(false);

    const { email } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });



    const onSubmit = e => {
        e.preventDefault();
        // const redirect_url = "https://api.equlion.com/api/v1/password-reset-confirm"
        const redirect_url = "http://127.0.0.1:8000/api/v1/user/password-reset-confirm"
        console.log("redirect_url",redirect_url)

        // "https://equlion.com/password-reset-confirm"
        resetPassword(email, redirect_url);
    };


    const trueOrFalse = () => {
        if (email === '') {
            return true;
        } else {
            return false;
        }
    }


    const showOnClick = () => {
        console.log(show)
        if (email !== '') {
            console.log(!show)
            setShow(true);
        }
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 py-6 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-lg">
          <form onSubmit={(e) => onSubmit(e)} className="space-y-2">
            {!show && (
              <div className="text-left text-gray-700 mb-4">
                <p>
                  {intl.formatMessage({
                    id: "RESET_PASSWORD.TEXT",
                  })}
                </p>
              </div>
            )}
  
            {/* Email Input */}
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                {intl.formatMessage({ id: "REGISTER.EMAIL" })}
              </label>
              <input
                type="email"
                id="email"
                name="email"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                value={email}
                onChange={onChange}
                autoFocus
              />
            </div>
  
            {/* Submit Button */}
            <button
              type="submit"
              className="w-full px-4 py-2 bg-pink-600 text-white font-semibold rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
              onClick={showOnClick}
            >
              {intl.formatMessage({ id: "RESET_PASSWORD.BUTTON" })}
            </button>
  
            {/* Alert Text if show is true */}
            {show && (
              <div className="mt-4 text-left text-sm text-gray-600">
                <p>
                  {intl.formatMessage({
                    id: "RESET_PASSWORD.TEXT_ALERT",
                  })}
                </p>
              </div>
            )}
          </form>
        </div>
      </div>
    )
};

export default ResetPassword