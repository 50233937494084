import React from 'react'
import { useDispatch } from 'react-redux';
import {
    BrowserRouter as Router,
    useLocation,
    useNavigate
} from "react-router-dom";
import { resetPassworConfirm } from 'redux/ApiCalls';
import storeConstants from 'redux/constants/actionTypes';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
const theme = createTheme();

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PasswordResetConfirm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const intl = useIntl()
    const query = useQuery();

    const resetToken = query.get('token');
    const uidb64 = query.get('uidb64');

    const onSubmit = e => {
        e.preventDefault();
        navigate('/password-reset-complete');

        dispatch(
            {
                type: storeConstants.PASSWORD_RESET_TOKEN_AND_UIDB64,
                payload: {
                    resetToken,
                    uidb64
                }
            })
        resetPassworConfirm(uidb64, resetToken)
            .then(res => {
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            })
    };


    return (
        <>{resetToken && uidb64 ?


            <div className="min-h-screen flex items-center justify-center bg-gray-100 py-6 px-4 sm:px-6 lg:px-8">
            <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-lg">
              {/* Logo Section */}
              <div className="text-center mb-8">
                <a
                  href="/"
                  className="flex justify-center items-center text-2xl font-bold text-pink-600"
                >
                  <span className="text-white">Equ</span>
                  <span className="text-pink-600">lion</span>
                </a>
              </div>
      
              {/* Reset Password Form */}
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                {/* Reset Password Button */}
                <button
                  type="submit"
                  className="w-full px-4 py-2 bg-pink-600 text-white font-semibold rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
                >
                  {intl.formatMessage({ id: 'RESET_PASSWORD.BUTTON' })}
                </button>
      
                {/* Info Text */}
                <div className="mt-4 text-sm text-center text-gray-600">
                  <p>
                    {intl.formatMessage({ id: "RESET_PASSWORD_CONFIRM.TEXT" })}
                  </p>
                </div>
              </form>
            </div>
          </div>
            : ""
        }
        </>
    )
}

export default PasswordResetConfirm;