// import React, { useEffect, useState } from 'react'
// import { Container, Nav, Navbar } from 'react-bootstrap'
// import { useIntl } from 'react-intl'
// import { useDispatch, useSelector } from 'react-redux'
// import CountryFlag from 'components/CountryFlag'
// import { logOut } from 'redux/actions/auth'
// import { toast } from 'react-toastify'
// import { Link, useNavigate } from 'react-router-dom'

// import Box from '@mui/material/Box'
// import SwipeableDrawer from '@mui/material/SwipeableDrawer'
// import Button from '@mui/material/Button'
// import List from '@mui/material/List'
// import ListItem from '@mui/material/ListItem'
// import ListItemText from '@mui/material/ListItemText'
// import styles from '.././../assets/styles/components/accordion/accordion.module.scss'
// import storeConstants from 'redux/constants/actionTypes'
// import { firmDelete, getFirmList } from 'redux/ApiCalls'

// const Header = () => {
// const intl = useIntl()
// const auth = useSelector((state) => state.auth)
// const navigate = useNavigate()
// const dispatch = useDispatch()
// const { firms } = useSelector((state) => state.getFirmList)

// const [state, setState] = useState({
//   top: false,
//   left: false,
//   bottom: false,
//   right: false,
// })

// useEffect(() => {
//   getFirmList(auth.token)
//     .then((res) => {
//       dispatch({
//         type: storeConstants.GET_FIRM_LIST,
//         payload: res.data.results,
//       })
//     })
//     .catch((e) => console.log(e))
// }, [])

// function changeLocation(placeToGo) {
//   navigate(placeToGo, { replace: true })
//   window.location.reload()
// }

// const toggleDrawer = (anchor, open) => (event) => {
//   if (
//     event &&
//     event.type === 'keydown' &&
//     (event.key === 'Tab' || event.key === 'Shift')
//   ) {
//     return
//   }
//   setState({ ...state, [anchor]: open })
// }

//   const list = (anchor) => (
//     <Box
//       sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
//       role="presentation"
//       onClick={toggleDrawer(anchor, false)}
//       onKeyDown={toggleDrawer(anchor, false)}
//     >
//       <List>
//         {firms.map((firm) => {
//           return (
//             <ListItem key={firm} disablePadding>
//               <div className={styles.link_and_delete}>
//                 <Link
//                   className={styles.link_and_delete_link}
//                   to={`/analysis/${firm.slug}`}
//                 >
//                   <Button
//                     className={styles.link_and_delete_link_button}
//                     variant="contained"
//                     onClick={() => {
//                       dispatch({
//                         type: storeConstants.TABS_ROUTER,
//                         payload: true,
//                       })
//                     }}
//                     size="small"
//                   >
//                     <ListItemText primary={firm.name} />
//                   </Button>
//                 </Link>
//                 <div className={styles.link_and_delete_delete}>
//                   <Button
//                     className={styles.link_and_delete_delete_button}
//                     onClick={() => {
//                       firmDelete(auth.token, firm.slug).then(() => {
//                         toast.success(`${firm.name} deleted`)
//                         getFirmList(auth.token)
//                           .then((res) => {
//                             dispatch({
//                               type: storeConstants.GET_FIRM_LIST,
//                               payload: res.data.results,
//                             })
//                             changeLocation('/analysis')
//                           })
//                           .catch((e) => console.log(e))
//                       })
//                     }}
//                     size="small"
//                     variant="outlined"
//                     color="error"
//                   >
//                     <i
//                       style={{ fontSize: '20px' }}
//                       className="fa fa-trash"
//                       aria-hidden="true"
//                     ></i>
//                   </Button>
//                 </div>
//               </div>
//             </ListItem>
//           )
//         })}
//       </List>

//       <Link
//         id={styles.accordion_main_container_content_add_link}
//         to="/analysis"
//         onClick={() => changeLocation('/analysis')}
//       >
//         <Button
//           type="submit"
//           fullWidth
//           size="small"
//           variant="contained"
//           id={styles.accordion_main_container_content_add_button}
//         >
//           {intl.formatMessage({
//             id: 'FIRMACCORDION.BUTTON_CREATE',
//           })}
//           <i className="fa fa-plus" aria-hidden="true"></i>
//         </Button>
//       </Link>
//     </Box>
//   )

//   return (
//     <header className="header">
//       <Navbar
//         collapseOnSelect
//         expand="lg"
//         variant="dark"
//         style={{ fontWeight: 'bold' }}
//       >
//         <Container>
//           <Navbar.Brand href="/homepage" className="header-menu-logo">
//           <>
//   <div className="">
//     <span
//       className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500 drop-shadow-lg"
//       id="nav-logo"
//     >
//       Financial
//     </span>
//     <span
//       className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-teal-500 drop-shadow-lg"
//       id="nav-logo"
//     >
//       Prediction
//     </span>
//     <span
//       className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-red-500 to-yellow-500 relative"
//       id="nav-logo"
//     >
//       <span
//         className="absolute inset-0 text-black opacity-20 blur-md"
//         style={{ zIndex: "-1" }}
//       >
//         AI
//       </span>
//       <span className="relative">AI</span>
//     </span>
//   </div>
// </>
//           </Navbar.Brand>
//           <Navbar.Toggle aria-controls="responsive-navbar-nav" />
//           <Navbar.Collapse>
//             <Nav className="mx-auto">
//               {auth.token ? (
//                 ['left'].map((anchor) => (
//                   <React.Fragment key={anchor}>
//                     <Nav.Link onClick={toggleDrawer(anchor, true)}>
// {intl.formatMessage({
//   id: 'NAV.BUTTON.ANALYSIS',
// })}
//                     </Nav.Link>
//                     <SwipeableDrawer
//                       anchor={anchor}
//                       open={state[anchor]}
//                       onClose={toggleDrawer(anchor, false)}
//                       onOpen={toggleDrawer(anchor, true)}
//                     >
//                       {list(anchor)}
//                     </SwipeableDrawer>
//                   </React.Fragment>
//                 ))
//               ) : (
//                 <Nav.Link href="/analysis">
//                   {intl.formatMessage({
//                     id: 'NAV.BUTTON.ANALYSIS',
//                   })}
//                 </Nav.Link>
//               )}

// {auth.token ? (
//   <Nav.Link href="/loan">
//     {intl.formatMessage({ id: 'SIDEBAR_ROUTES.LOANS_REQUESTS' })}
//   </Nav.Link>
// ) : (
//   <Nav.Link href="/get-loan">
//     {intl.formatMessage({ id: 'SIDEBAR_ROUTES.LOANS_REQUESTS' })}
//   </Nav.Link>
// )}

//               {auth.token ? (
//                 <Nav.Link href="/equity">
//                   {intl.formatMessage({ id: 'SIDEBAR_ROUTES.EQUITY_REQUESTS' })}
//                 </Nav.Link>
//               ) : (
//                 <Nav.Link href="/get-equity">
//                   {intl.formatMessage({ id: 'SIDEBAR_ROUTES.EQUITY_REQUESTS' })}
//                 </Nav.Link>
//               )}

// {auth.token ? (
//   <>
//     <Nav.Link href="/myfirm">
//       {intl.formatMessage({
//         id: 'NAV.BUTTON.MY_FIRM',
//       })}
//     </Nav.Link>
//     <Nav.Link href="/myfirm-update">
//       {intl.formatMessage({ id: 'SIDEBAR_ROUTES.ACCOUNT' })}
//     </Nav.Link>
//     <Nav.Link
//       onClick={() =>
//         dispatch(
//           logOut(),
//           navigate('/homepage'),
//           toast.info('successfully logged out')
//         )
//       }
//     >
//       {intl.formatMessage({
//         id: 'NAV.BUTTON.LOGOUT',
//       })}
//     </Nav.Link>
//   </>
// ) : (
//   <>
//     <Nav.Link href="/login">
//       {intl.formatMessage({
//         id: 'NAV.BUTTON.LOGIN',
//       })}
//     </Nav.Link>
//     <Nav.Link href="/register">
//       {intl.formatMessage({
//         id: 'NAV.BUTTON.SIGN_UP',
//       })}
//     </Nav.Link>
//   </>
// )}
//               <CountryFlag />
//             </Nav>
//           </Navbar.Collapse>
//         </Container>
//       </Navbar>
//     </header>
//   )
// }

// export default Header

import React, { useEffect, useState } from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import CountryFlag from 'components/CountryFlag'
import { logOut } from 'redux/actions/auth'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import { firmDelete, getFirmList } from 'redux/ApiCalls'
import storeConstants from 'redux/constants/actionTypes'
import styles from '.././../assets/styles/components/accordion/accordion.module.scss'

const Header = () => {
  const intl = useIntl()
  const auth = useSelector((state) => state.auth)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { firms } = useSelector((state) => state.getFirmList)

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  useEffect(() => {
    getFirmList(auth.token)
      .then((res) => {
        dispatch({
          type: storeConstants.GET_FIRM_LIST,
          payload: res.data.results,
        })
      })
      .catch((e) => console.log(e))
  }, [])

  function changeLocation(placeToGo) {
    navigate(placeToGo, { replace: true })
    window.location.reload()
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setState({ ...state, [anchor]: open })
  }
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="bg-gradient-to-l from-[#107eff] via-[#0062d4] to-[#01277c]">
    <nav aria-label="Global" className="mx-auto flex max-w-7xl items-center justify-between p-3 lg:px-4">
      {/* Logo */}
   

      {/* Hamburger Menu Button for Mobile */}
    
      {/* Desktop Navigation Links */}
      <div className="flex lg:flex-1">
          <a href="#" className="-m-1.5 p-1.5">
            <h1 className="flex items-center text-1xl font-bold text-white">
              FinancialPrediction
              <span className="bg-blue-100 text-blue-800 text-base font-semibold me-2 px-2 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ms-1">
                Al
              </span>
            </h1>
          </a>
        </div>

        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <a href="/analysis" className="text-sm/6 font-semibold text-white">
            {intl.formatMessage({
              id: 'NAV.BUTTON.ANALYSIS',
            })}
          </a>
        </div>

        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
          </button>
        </div>
        {auth.token ? (
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a href="/loan" className="text-sm/6 font-semibold text-white">
              {intl.formatMessage({ id: 'SIDEBAR_ROUTES.LOANS_REQUESTS' })}
            </a>
          </div>
        ) : (
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">

            <a href="/get-loan" className="text-sm/6 font-semibold text-white">
              {intl.formatMessage({ id: 'SIDEBAR_ROUTES.LOANS_REQUESTS' })}
            </a>
          </div>
        )}

        {auth.token ? (
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a href="/equity" className="text-sm/6 font-semibold text-white">
              {intl.formatMessage({ id: 'SIDEBAR_ROUTES.EQUITY_REQUESTS' })}
            </a>
          </div>
        ) : (
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a href="/get-equity" className="text-sm/6 font-semibold text-white">
              {intl.formatMessage({ id: 'SIDEBAR_ROUTES.EQUITY_REQUESTS' })}
            </a>
          </div>
        )}
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <a  className="text-sm/6 font-semibold text-white">
              
              </a>
            </div>
        {auth.token ? (
          <>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <a href="/myfirm" className="text-sm/6 font-semibold text-white">
                {intl.formatMessage({
                  id: 'NAV.BUTTON.MY_FIRM',
                })}
              </a>
            </div>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <a href="/myfirm-update" className="text-sm/6 font-semibold text-white">
                {intl.formatMessage({ id: 'SIDEBAR_ROUTES.ACCOUNT' })}
              </a>
            </div>

            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <a onClick={() =>
                dispatch(
                  logOut(),
                  navigate('/homepage'),
                  toast.info('successfully logged out')
                )
              } href="/myfirm-update" className="text-sm/6 font-semibold text-white">
                {intl.formatMessage({
                  id: 'NAV.BUTTON.LOGOUT',
                })}
              </a>
            </div>
          </>
        ) : (
          <>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <a href="/login" className="text-sm/6 font-semibold text-white">
                {intl.formatMessage({
                  id: 'NAV.BUTTON.LOGIN',
                })}
              </a>
            </div>
            <div className="hidden lg:flex lg:flex-1 lg:justify-center">
              <a href="/register" className="text-sm/6 font-semibold text-white">
                {intl.formatMessage({
                  id: 'NAV.BUTTON.SIGN_UP',
                })}
              </a>
            </div>

          </>
        )}

                    <div className=" lg:flex lg:flex-1 lg:justify-start">

        </div>
        <div className="flex lg:hidden">
        <button
          type="button"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
        >
          <span className="sr-only">Open main menu</span>
          {/* Hamburger Icon */}
          <svg
            className="h-6 w-6"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>

      {/* Mobile Menu */}
      {mobileMenuOpen && (
        <div className="absolute inset-x-0 top-16 z-50 shadow-lg bg-gradient-to-l from-[#107eff] via-[#0062d4] to-[#01277c]">
          <div className="flex flex-col gap-4 p-4">
            <a href="/analysis" className="text-sm/6 font-semibold text-gray-900">
              {intl.formatMessage({ id: 'NAV.BUTTON.ANALYSIS' })}
            </a>
            {auth.token ? (
              <>
                <a href="/loan" className="text-sm/6 font-semibold text-gray-900">
                  {intl.formatMessage({ id: 'SIDEBAR_ROUTES.LOANS_REQUESTS' })}
                </a>
                <a href="/equity" className="text-sm/6 font-semibold text-gray-900">
                  {intl.formatMessage({ id: 'SIDEBAR_ROUTES.EQUITY_REQUESTS' })}
                </a>
                <a href="/myfirm" className="text-sm/6 font-semibold text-gray-900">
                  {intl.formatMessage({ id: 'NAV.BUTTON.MY_FIRM' })}
                </a>
                <a
                  href="/myfirm-update"
                  onClick={() => {
                    dispatch(logOut());
                    navigate('/homepage');
                    toast.info('Successfully logged out');
                  }}
                  className="text-sm/6 font-semibold text-gray-900"
                >
                  {intl.formatMessage({ id: 'NAV.BUTTON.LOGOUT' })}
                </a>
              </>
            ) : (
              <>
                <a href="/login" className="text-sm/6 font-semibold text-gray-900">
                  {intl.formatMessage({ id: 'NAV.BUTTON.LOGIN' })}
                </a>
                <a href="/register" className="text-sm/6 font-semibold text-gray-900">
                  {intl.formatMessage({ id: 'NAV.BUTTON.SIGN_UP' })}
                </a>
              </>
            )}
            <CountryFlag />

          </div>
          
        </div>
      )}
      
    </nav>
  </header>
  )
}

export default Header
