import storeConstants from '../constants/actionTypes'


const inintialState = {
    user: [],
}


const getUser = (state = inintialState, action) => {
    switch (action.type) {
        case storeConstants.GET_USER:
            return {
                ...state,
                user: action.payload,
            }
        default:
            return state
    }
}

export default getUser