

import { Outlet, useLocation } from 'react-router-dom';
import { Header } from 'components/layout';

const AppLayout = () => {
  const location = useLocation();

  // Eğer rota "/analysis" ise, Header'ı göstermeyelim
  const shouldShowHeader = location.pathname !== '/analysis';

  return (
    <>
      {shouldShowHeader && <Header />} {/* Sadece header'ı göstermek istediğimizde */}
      <main>
        <Outlet />
      </main>
    </>
  );
};

export default AppLayout;

