import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as analysisDataActions from '../redux/actions/analysisDataActions';
import { FaCheck } from 'react-icons/fa';

const CurrencyInfo = ({ excelRead, setExcelRead, showFirstButton, showSecondButton }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { firmBackground } = useSelector((state) => state.analysisDataReducer);

  const handleFlowChange = () => {
    dispatch(
      analysisDataActions.firmBackgroundSelect({
        ...firmBackground,
        flowOrstock: true,
      })
    );
  };

  const handleStockChange = () => {
    dispatch(
      analysisDataActions.firmBackgroundSelect({
        ...firmBackground,
        flowOrstock: false,
      })
    );
  };

  return (
    <div className="text-black bg-white flex text-xxs border-gray-200  sm:text-xxs nowrap justify-start w-full  bg-transparent">
        {showFirstButton && (
          <button
            className={`px-2 py-1 border border-gray-200 text-xs flex items-center justify-center space-x-1 w-full ${
              firmBackground.flowOrstock
              ? 'bg-gray-100 text-blue-700 hover:bg-gray-100 '
              : 'bg-white text-black-700  hover:bg-gray-100 '
            }`}
            onClick={handleFlowChange}
          >
            {firmBackground.flowOrstock && <FaCheck className="text-gray-900" />}
            <span>Flow</span>
          </button>
        )}
        {showSecondButton && (
          <button
            className={`px-2 py-1 border border-gray-200  text-xs flex items-center justify-center space-x-1 w-full ${
              !firmBackground.flowOrstock
                ? 'bg-gray-100 text-blue-700 hover:bg-gray-100 '
                : 'bg-white text-black-700  hover:bg-gray-300  '
            }`}
            onClick={handleStockChange}
          >
            {!firmBackground.flowOrstock && <FaCheck className="text-gray-900" />}
            <span>Stock</span>
          </button>
        )}
      </div>
  );
};

export default CurrencyInfo;
